import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Col, Container, Alert, Badge, Tooltip, Modal, Table } from "react-bootstrap";
import { MyForm, MyFormControl, MySelect } from "../../FormikHooks";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import { useDecodedParams, convertirDatosGenericosAOption } from "../../Utilidades";
import { useApi } from "ApiHooks";
import { DateTime } from "luxon";
import { useAdvAPI } from './AdvancedApi';
import { useHistory } from "react-router";
import { DialogoConfirmar, DialogoConfirmarRef } from "DialogoConfirmar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DireccionImportador } from "modelos/Advanced";
import BlockUi from "react-block-ui";

let schema = Yup.object({

    'CuitDespachante': Yup.string().nullable().required('Debe seleccionar un despachante'),
    'Declaracion': Yup.string().required('Debe ingresar una declaracion').test(
        'Ancho', 'Debe tener 16 caracteres',
        (val) => val !== null && val !== undefined && (val.length > 0 && val?.length <= 16)),
    'Calle': Yup.string().nullable().required('Debe ingresar Calle').test(
        'Ancho',
        'Calle debe ser menor o igual a 40 caracteres',
        (val) => val !== null && val !== undefined && (val.length > 0 && val.length <= 40)),
    'Numero': Yup.string().nullable().required('Debe ingresar numero valido').test(
        'Ancho',
        'El numero de calle debe ser menor o igual a 5 digitos',
        (val) => val !== null && val !== undefined && (val.toString().length > 0 && val.toString().length <= 5)),
    'Localidad': Yup.string().nullable().required('Debe ingresar Localidad').test(
        'Ancho',
        'Localidad debe ser menor o igual a 40 caracteres',
        (val) => val !== null && val !== undefined && (val.length > 0 && val.length <= 30)),
    'Telefono': Yup.string().nullable().required('Debe ingresar Telefono').test(
        'Ancho',
        'Telefono debe ser menor o igual a 40 caracteres',
        (val) => val !== null && val !== undefined && (val.length > 0 && val.length <= 30)),
    'Fecha': Yup.string().nullable().required('Debe ingresar Fecha'),
    'Hora': Yup.string().nullable().required('Debe ingresar Hora'),
    'Observaciones': Yup.string().optional().max(250, 'Observaciones debe ser menor o igual a 250 caracteres')
});

export function Avisodecarga() {
    let api = useApi();
    let formikRef = React.useRef<FormikProps<any>>(null);
    let huboCambios = React.useRef<{ valor: boolean }>({ valor: false });
    let { nroDespacho } = useDecodedParams() as { nroDespacho: string };
    let apiADV = useAdvAPI();
    let history = useHistory();
    let refDialogo = useRef<DialogoConfirmarRef>(null);
    let calleRef = useRef<HTMLInputElement>(null);
    let [mensajeDialogo, updateMensajeDialogo] = useState('');

    const [avcEnviado, updateavcEnviado] = useState(false);
    const [avcEnvioError, updateavcEnvioError] = useState(false);
    const [avcEnvioErrorMsg, updateavcEnvioErrorMsg] = useState('');
    const [IdentificadorAVC, updateIdentificadorAVC] = useState('');
    const [errorGetDespacho, setErrorGetDespacho] = useState('');
    const [modalDireccionesShow, setModalDireccionesShow] = useState(false);
    const [modalConfirmarBorrarShow, setModalConfirmarBorrarShow] = useState(false);
    const [modalEditarDireccionShow, setModalEditarDireccionShow] = useState(false);
    const [modalAlertasErrores, setModalAlertasErroresShow] = useState(false)
    const [cuitImportador, setCuitImportador] = useState('')
    const [direccionesImportador, setDireccionesImportador] = useState<DireccionImportador[] | string>([])
    const [cargando, setCargando] = useState(false);
    const [idDireccion, setIDdireccion] = useState<number>()
    const [direccionEditar, setDireccionEditar] = useState<DireccionImportador>()
    const [error, setError] = useState<any[] | string>('')
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    const eventoHuboCambios = () => {
        huboCambios.current.valor = true;
    }

    const getDirecciones = async () => {
        setCargando(true)
        await apiADV.getDireccionesPorImportador({ CuitImportador: cuitImportador }).then((response) => {
            setDireccionesImportador(response);
            setCargando(false)
        }).catch((error) => {
            setCargando(false)
        })
    }

    const borrarDireccion = async () => {
        setCargando(true)
        await apiADV.deleteDireccionImportador({ Generadorid: idDireccion! }).then((response) => {
            if (typeof response === 'boolean' && true) {
                setModalConfirmarBorrarShow(false)
                getDirecciones()
            } else {
                setModalConfirmarBorrarShow(false)
                setCargando(false)
                setError(response)
            }
        }).catch((error) => {
            setModalConfirmarBorrarShow(false)
            setCargando(false)
            setError(error)
        })
    }

    const editarDireccion = async () => {
        setCargando(true)
        await apiADV.updateDireccionImportador(direccionEditar!).then((response) => {
            if (typeof response === 'boolean' && true) {
                setModalEditarDireccionShow(false)
                getDirecciones()
            } else {
                setModalEditarDireccionShow(false)
                setCargando(false)
                setError(response)
            }
        }).catch((error) => {
            setModalEditarDireccionShow(false)
            setCargando(false)
            setError(error)
        })
    }

    async function cargarDespacho(despacho: string, cuitDespachante: string) {
        setCargando(true)
        let aduana: string;
        aduana = despacho.substring(2, 5);
        let respuesta = await apiADV.getAvisoDeCargaConsulta(
            despacho,
            cuitDespachante,
            aduana
        );

        if (respuesta.Exito) {

            formikRef.current?.setFieldValue('CuitDespachante', cuitDespachante);
            formikRef.current?.setFieldValue('Declaracion', despacho);
            formikRef.current?.setFieldValue('FechaOficializacion', respuesta.ConsultaAvisoDatos.FechaOficializacion);
            formikRef.current?.setFieldValue('Importador', respuesta.ConsultaAvisoDatos.CuitImportador);
            formikRef.current?.setFieldValue('NombreImportador', respuesta.ConsultaAvisoDatos.DescripcionImportador);
            formikRef.current?.setFieldValue('Deposito', respuesta.ConsultaAvisoDatos.CodigoDeposito);
            formikRef.current?.setFieldValue('NombreDeposito', respuesta.ConsultaAvisoDatos.DescripcionDeposito);
            setCuitImportador(respuesta.ConsultaAvisoDatos.CuitImportador)
            calleRef?.current?.focus();
            setErrorGetDespacho('');
            setCargando(false)

        } else {

            //formikRef.current?.setFieldValue('CuitDespachante','');
            //formikRef.current?.setFieldValue('Declaracion','');
            formikRef.current?.setFieldValue('FechaOficializacion', '');
            formikRef.current?.setFieldValue('Importador', '');
            formikRef.current?.setFieldValue('NombreImportador', '');
            formikRef.current?.setFieldValue('Deposito', '');
            formikRef.current?.setFieldValue('NombreDeposito', '');
            setErrorGetDespacho(respuesta.Msg);
            setCargando(false)

        }

    }

    //buscar despacho si presiono enter
    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            let despachante = formikRef.current?.values.CuitDespachante;
            let despacho = event.target.value;

            if (despacho?.length === 16 && despachante?.length === 11) {
                cargarDespacho(despacho, despachante);
            }

        }
    }

    async function tirarAvisoDeCarga(values: any, actions: FormikHelpers<any>) {
        updateavcEnviado(false);
        updateMensajeDialogo(`
            <div style="text-align: center;">
                <h2 style="font-size: 24px; font-weight: bold;">Confirma dar Aviso de Carga</h2>
                <p style="font-size: 18px;"><strong>Despacho:</strong> ${values?.Declaracion}</p>
                <p style="font-size: 18px;"><strong>Dirección:</strong> ${values?.Calle} Nro ${values?.Numero}</p>
                <p style="font-size: 18px;"><strong>Localidad:</strong> ${values?.Localidad}</p>
                <p style="font-size: 18px;"><strong>Día:</strong> ${DateTime.fromISO(values?.Fecha).toFormat("dd/MM/yyyy")} 
                <strong>Hora:</strong> ${values?.Hora}</p>
            </div>
        `);

        let promesa = refDialogo.current?.mostrar().then(() => true).catch(() => false && setCargando(false));

        if (await promesa) {
            
            if (promesa) {
                try {
                    setCargando(true);

                    let respuesta = await apiADV.avisoDeCargaEnviar(
                        values.Declaracion.substring(2, 5),
                        values.Declaracion,
                        values.CuitDespachante,
                        DateTime.fromISO(values.Fecha).toFormat('dd/MM/yyyy'),
                        values.Hora,
                        values.Calle,
                        values.Numero,
                        values.Localidad,
                        values.Observaciones ? values.Observaciones : "",
                        values.Telefono)

                    updateIdentificadorAVC(respuesta);
                    updateavcEnviado(true);
                    setModalAlertasErroresShow(true)
                    updateavcEnvioError(false);
                    setCargando(false);
                    formikRef.current?.resetForm();
                    return true;

                } catch (error: any) {
                    if (error.response?.status === 500) {
                        updateavcEnvioErrorMsg(error.response.data);
                        setModalAlertasErroresShow(true)
                        setCargando(false);
                    } else {
                        updateavcEnvioErrorMsg('Kit Malvina no responde, por favor reintente nuevamente.' + error?.response?.status);
                        setModalAlertasErroresShow(true)
                        setCargando(false);
                    }
                    updateavcEnvioError(true);
                    //mostrarError('error data ' + error.response.data);
                }
                setCargando(false);

            }
            else {
                setCargando(false);
                return false;
            }
        }
    }

    const seleccionarDireccion = (direccion: DireccionImportador) => {
        setModalDireccionesShow(false);
        formikRef.current?.setFieldValue('Calle', direccion.Calle);
        formikRef.current?.setFieldValue('Numero', direccion.NroPuerta);
        formikRef.current?.setFieldValue('Localidad', direccion.Localidad);
        formikRef.current?.setFieldValue('Telefono', direccion.Telefono);
        formikRef.current?.setFieldValue('Observaciones', direccion.Observacion);
    }

    const openModalGetDirecciones = () => {
        getDirecciones()
        setModalDireccionesShow(true)
    }

    useEffect(() => {
        if (nroDespacho) {
            let despacho = nroDespacho.split("|")[0];
            let despachante = nroDespacho.split("|")[1];
            cargarDespacho(despacho, despachante);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.key.toLowerCase() === "d" && cuitImportador?.length > 0) {
                event.preventDefault();
                openModalGetDirecciones();
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cuitImportador]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (direccionesImportador.length === 0) return;

            if (event.key === "ArrowDown") {
                event.preventDefault();
                setSelectedIndex((prevIndex) => {
                    let newIndex = prevIndex === null ? 0 : prevIndex + 1;
                    return newIndex >= direccionesImportador.length ? prevIndex : newIndex;
                });
            }

            if (event.key === "ArrowUp") {
                event.preventDefault();
                setSelectedIndex((prevIndex) => {
                    let newIndex = prevIndex === null ? 0 : prevIndex - 1;
                    return newIndex < 0 ? 0 : newIndex;
                });
            }

            if (event.key === "Enter" && selectedIndex !== null && typeof direccionesImportador !== 'string') {
                seleccionarDireccion(direccionesImportador[selectedIndex])
                setModalDireccionesShow(false)
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [selectedIndex, direccionesImportador, modalDireccionesShow]);

    return (

        <Container>

            <br /><p className="h3">Aviso de Carga</p>

            <Formik initialValues={{}} validationSchema={schema} validateOnBlur={true} innerRef={formikRef} onSubmit={tirarAvisoDeCarga}>
                <BlockUi blocking={cargando} message="Cargando...">
                    <MyForm className="col-md-9">

                        <Form.Row>
                            <Form.Group as={Col}>
                                <MySelect
                                    name="CuitDespachante"
                                    label="Despachante"
                                    options={() => api.getDespachantes().then(convertirDatosGenericosAOption)} onValueChange={eventoHuboCambios}
                                />

                            </Form.Group>
                        </Form.Row>

                        <span className="font-weight-bold">Declaraciones</span> <br /><br />

                        <Form.Row>
                            <Form.Group as={Col} className="col-md-3 align-items-center justify-content-center">

                                <MyFormControl name="Declaracion" type="text" label="Declaracion" onKeyDown={handleKeyDown}></MyFormControl>
                                {errorGetDespacho && <Alert variant="warning">{errorGetDespacho}</Alert>}
                                <Tooltip id="error" >test error de despacho</Tooltip>

                            </Form.Group>
                            <Form.Group as={Col} className="col-auto align-middle">
                                <Button className="align-self-end my-4" onClick={() => { (history.push('/advanced/declaracionesoficializadas')); }} >...</Button>
                            </Form.Group>
                            <Form.Group as={Col} className="col-md-8">
                                <MyFormControl name="FechaOficializacion" readOnly type="text" label="Fecha Oficializacion" ></MyFormControl>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} className="col-md-3">
                                <MyFormControl name="Importador" readOnly type="text" label="Importador" ></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col} className="col-md-9">
                                <MyFormControl name="NombreImportador" readOnly type="text" label="Nombre" ></MyFormControl>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} className="col-md-3">
                                <MyFormControl name="Deposito" readOnly type="text" label="Deposito"></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col} className="col-md-9">
                                <MyFormControl name="NombreDeposito" readOnly type="text" label="Nombre" ></MyFormControl>
                            </Form.Group>
                        </Form.Row>


                        <span className="font-weight-bold">Aviso</span> <br /><br />
                        <Button disabled={cuitImportador?.length === 0} style={{ marginBottom: 10 }} onClick={() => openModalGetDirecciones()}>Direcciones [CTRL + D]</Button>
                        <Form.Row>
                            <Form.Group as={Col} className="col-md-9">
                                <MyFormControl name="Calle" type="text" ref={calleRef} label="Calle" ></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col} className="col-md-3">
                                <MyFormControl name="Numero" type="text" label="Numero" ></MyFormControl>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} className="col-md-8">
                                <MyFormControl name="Localidad" type="text" label="Localidad" ></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col} className="col-md-4">
                                <MyFormControl name="Telefono" type="text" label="Telefono" ></MyFormControl>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} className="col-md-3">
                                <MyFormControl name="Fecha" type="date" label="Fecha" ></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col} className="col-md-2">
                                <MyFormControl name="Hora" type="time" label="Hora" ></MyFormControl>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} className="col-md-12">
                                <MyFormControl name="Observaciones" type="text" label="Observaciones" ></MyFormControl>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} className="col-md-12">
                                <Button onClick={() => formikRef.current?.submitForm()}>
                                    Tirar Aviso de Carga
                                </Button>
                            </Form.Group>
                        </Form.Row>
                    </MyForm>

                </BlockUi>
            </Formik>

            <DialogoConfirmar ref={refDialogo} mensaje={mensajeDialogo} textoBotonConfirmar="Sí" textoBotonCancelar="No" />

            <Modal
                closeButton={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={modalDireccionesShow} onHide={() => setModalDireccionesShow(false)} animation={false}>
                <Modal.Header style={{backgroundColor:'#216aeb'}}>
                    <Modal.Title><p style={{ color:'white'}}>Direcciones</p></Modal.Title>
                </Modal.Header>
                <BlockUi blocking={cargando} message="Cargando...">
                    <Modal.Body>
                        {typeof direccionesImportador === 'string' ?
                            <>
                                <Alert style={{ display: 'flex', justifyContent: 'center' }} variant="danger">
                                    <Alert.Heading>{direccionesImportador}</Alert.Heading>
                                </Alert>
                            </>
                            : 
                            <Table striped bordered hover variant="light">
            <thead>
                <tr>
                    <th>Calle</th>
                    <th>Número</th>
                    <th>Localidad</th>
                    <th>Teléfono</th>
                    <th>Botones</th>
                </tr>
            </thead>
            <tbody>
                {direccionesImportador.map((direccion, index) => (
                    <tr
                        key={index}
                        style={{
                            backgroundColor: selectedIndex === index ? "#d3d3d3" : "transparent",
                        }}
                    >
                        <td>{direccion.Calle}</td>
                        <td>{direccion.NroPuerta}</td>
                        <td>{direccion.Localidad}</td>
                        <td>{direccion.Telefono}</td>
                        <td>
                            <Button onClick={() => seleccionarDireccion(direccion)}>Seleccionar</Button>
                            <FontAwesomeIcon
                                title="Editar"
                                style={{ fontSize: 22, cursor: "pointer", color: "gray", marginLeft: 10 }}
                                icon={faEdit}
                                onClick={() => {
                                    setDireccionEditar(direccion);
                                    setModalEditarDireccionShow(true);
                                }}
                            />
                            <FontAwesomeIcon
                                title="Borrar"
                                style={{ fontSize: 22, cursor: "pointer", color: "#db3939", marginLeft: 10 }}
                                icon={faTrash}
                                onClick={() => {
                                    setIDdireccion(direccion.Generadorid);
                                    setModalConfirmarBorrarShow(true);
                                }}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>}
                    </Modal.Body>
                </BlockUi>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalDireccionesShow(false)}>
                        Cerrar [ESC]
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="sm"
                style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}
                aria-labelledby="contained-modal-title-vcenter"
                centered show={modalConfirmarBorrarShow} onHide={() => setModalConfirmarBorrarShow(false)} animation={false}>
                <BlockUi blocking={cargando} message="Borrando Dirección...">
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar Borrar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>¿Está seguro de querer borrar la dirección guardada?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setModalConfirmarBorrarShow(false)}>
                            Cancelar
                        </Button>
                        <Button variant="danger" onClick={() => borrarDireccion()}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </BlockUi>

            </Modal>

            <Modal
                size="lg"
                style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}
                aria-labelledby="contained-modal-title-vcenter"
                centered show={modalEditarDireccionShow} onHide={() => setModalEditarDireccionShow(false)} animation={false}>
                <Modal.Header closeButton={false}>
                    <Modal.Title>Editar Dirección</Modal.Title>
                </Modal.Header>
                <BlockUi blocking={cargando} message="Editando Dirección...">
                    <Modal.Body>
                        <Container>
                            <Form.Group className="mb-3" controlId="formBasicCalle">
                                <Form.Label>Calle</Form.Label>
                                <Form.Control type="text" value={direccionEditar?.Calle} onChange={(e) => setDireccionEditar(direccionEditar ? { ...direccionEditar, Calle: e.target.value } : undefined)} placeholder="Ingrese Calle.." />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicNumero">
                                <Form.Label>Número</Form.Label>
                                <Form.Control type="text" value={direccionEditar?.NroPuerta} onChange={(e) => setDireccionEditar(direccionEditar ? { ...direccionEditar, NroPuerta: e.target.value } : undefined)} placeholder="Número.." />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicLocalidad">
                                <Form.Label>Localidad</Form.Label>
                                <Form.Control type="text" value={direccionEditar?.Localidad} onChange={(e) => setDireccionEditar(direccionEditar ? { ...direccionEditar, Localidad: e.target.value } : undefined)} placeholder="Localidad.." />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicTelefono">
                                <Form.Label>Telefono</Form.Label>
                                <Form.Control type="text" value={direccionEditar?.Telefono} onChange={(e) => setDireccionEditar(direccionEditar ? { ...direccionEditar, Telefono: e.target.value } : undefined)} placeholder="Telefono.." />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicObservaciones">
                                <Form.Label>Observaciones</Form.Label>
                                <Form.Control type="text" value={direccionEditar?.Observacion} onChange={(e) => setDireccionEditar(direccionEditar ? { ...direccionEditar, Observacion: e.target.value } : undefined)} placeholder="Observaciones.." />
                            </Form.Group>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setModalEditarDireccionShow(false)}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={() =>
                            editarDireccion()
                        }>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </BlockUi>
            </Modal>

            <Modal

                size="lg"
                style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}
                aria-labelledby="contained-modal-title-vcenter"
                centered show={modalAlertasErrores} onHide={() => setModalAlertasErroresShow(false)} animation={false}>
                <BlockUi blocking={cargando} message="Cargando..">
                    <Modal.Header closeButton={false}>
                        <Modal.Title>Alerta</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                            <Form.Row>
                                <Form.Group as={Col} className="col-md-12">
                                    {avcEnviado &&
                                        <Alert variant="success">
                                            <Alert.Heading> <FontAwesomeIcon icon={faInfoCircle} className="mr-1" /> Enviado correctamente!</Alert.Heading>
                                            <p>El Aviso de Carga fue enviado exitosamente,   <Badge className="text-align-center" variant="secondary">Identificador Nro: <b>{IdentificadorAVC}</b></Badge></p>

                                            <hr />
                                            <p className="mb-0">
                                                para buscar otras operaciones <Alert.Link onClick={() => { (history.push('/advanced/declaracionesoficializadas')); }}>Click Aqui</Alert.Link>
                                            </p>
                                        </Alert>
                                    }
                                </Form.Group>

                                <Form.Group as={Col} className="col-md-12">
                                    {avcEnvioError &&
                                        <Alert variant="danger">
                                            <Alert.Heading>Error al Enviar un Aviso de Carga!</Alert.Heading>
                                            <p><h1><b>{avcEnvioErrorMsg}</b></h1></p>
                                        </Alert>
                                    }
                                    {error?.length > 0 &&
                                        <Alert variant="danger">
                                            <Alert.Heading>Ocurrio un error</Alert.Heading>
                                            <p><h1><b>{error}</b></h1></p>
                                        </Alert>
                                    }
                                </Form.Group>
                            </Form.Row>
                        </>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            updateavcEnvioError(false)
                            setError('')
                            setModalAlertasErroresShow(false)
                            updateavcEnvioErrorMsg('')
                        }}>
                            Cerrar [ESC]
                        </Button>
                    </Modal.Footer>
                </BlockUi>

            </Modal>
        </Container>

    )
}
