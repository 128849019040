import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { AppContext } from "App";
import { toDateTime } from "Utilidades";
import * as Yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { MyForm, MyFormCheck, MyFormControl, MySelect, MySelectConOptionABM, MyTextarea } from "../../FormikHooks";
import { Button, ButtonGroup, Container, Form, Modal, Table } from "react-bootstrap";
import { MyModal } from "../../MyModal";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import { Tareas } from "./Tareas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faCaretUp, faCaretDown, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { isNullOrWhiteSpace } from "../../Utilidades";
import { useCallback } from "react";
import BlockUi from "react-block-ui";
import styled from "styled-components";
import { PlantillasCaratula, PlantillasCaratulaRef } from "Paginas/Caratulas/PlantillasCaratula";
import { useApi, TipoLock } from "ApiHooks";
import './tareaCaratula.css'
import { storageFactory } from "storage-factory";
enum EstadoModal {
    Cerrado,
    Creando,
    Modificando
}

enum EstadoTareaCaratula {
    NoRealizado,
    Realizado
}

const estadosDisponibles = [{ value: EstadoTareaCaratula.NoRealizado.toString(), label: 'No Realizado' },
{ value: EstadoTareaCaratula.Realizado.toString(), label: 'Realizado' }];

let TablaGrilla = (props: React.PropsWithChildren<{ className?: string }>) => {
    let className;
    if (!isNullOrWhiteSpace(props.className)) {
        className = props.className + ' middle-vertical-align';
    } else {
        className = 'middle-vertical-align';
    }
    return (<Table bordered striped hover size="sm" className={className}>{props.children}</Table >);
}

TablaGrilla = styled(TablaGrilla)`
    td:nth-child(4){
        min-width:20em;
    }
    td:nth-child(2){
        max-width:50vw;
        word-wrap:break-word;
    }
`;
function GrillaTareasCaratula(props: {
    tareasCaratula: any[],
    eventoModificar: (item: any) => void,
    eventoCambiarEstado: (item: any) => void,
    eventoSubir: (item: any) => void,
    eventoBajar: (item: any) => void,
    eventoEliminar: (item: any) => void
}) {
    return <>
        <TablaGrilla>
            <thead>
                <tr>
                    <th>Tarea</th>
                    <th>Descripción</th>
                    <th>Estado</th>
                    {/* <th>Usuario Creación</th>
                    <th>Fecha Creación</th> */}
                    <th>Fecha</th>
                    <th>Reportable</th>
                    <th>Usuario</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>{props.tareasCaratula.sort((a: any, b: any) => (a.Orden ?? 0) - (b.Orden ?? 0)).map((t: any, indice: number) =>
            (<tr className="cursor-pointer" key={t.Id} onClick={e => {
                let element = e.target as HTMLElement;
                //ejecutar solamente cuando el target sea una celda de la tabla y no un botón o checkbox
                if (element.nodeName === 'TD' || element.nodeName === 'TR') {
                    props.eventoModificar(t);
                }
            }}>
                <td className="font-weight-bold">{t.Nombre}</td>
                <div style={{ overflowY: t?.Descripcion?.length > 50 ? 'scroll' : 'hidden', maxHeight: '5rem', width: '300px' }}><td >{t.Descripcion}</td></div>
                <td className="text-center">{t.Estado !== null && t.Estado !== undefined ?
                    <Form.Check type="switch" id={`estado-${t.Id}`} title="Realizado"
                        checked={t.Estado === EstadoTareaCaratula.Realizado}
                        onChange={(e: any) => {
                            props.eventoCambiarEstado({
                                ...t,
                                Estado: e.target.checked ? EstadoTareaCaratula.Realizado : EstadoTareaCaratula.NoRealizado
                            });
                        }}></Form.Check> : ''}</td>
                {/* <td>{t.NombreCreadoPor}</td>
                <td>{toDate(t.CreadoEl)}</td> */}
                <td>{toDateTime(t.ModificadoEl)}</td>
                <td className="text-center">
                    {t.Reportable ? <FontAwesomeIcon size="lg" icon={faFileExcel}></FontAwesomeIcon> : ''}
                </td>
                <td>{t.NombreModificadoPor}</td>
                <td>
                    <Button variant="link" onClick={e => {
                        props.eventoSubir(t);
                        e.preventDefault();
                        e.stopPropagation();
                    }} disabled={indice === 0}><FontAwesomeIcon icon={faCaretUp} /></Button>
                    <Button variant="link" onClick={e => {
                        props.eventoBajar(t);
                        e.preventDefault();
                        e.stopPropagation();
                    }} disabled={indice === props.tareasCaratula.length - 1} ><FontAwesomeIcon icon={faCaretDown} /></Button>
                    <Button variant="link" onClick={e => {
                        props.eventoEliminar(t);
                        e.preventDefault();
                        e.stopPropagation();
                    }}><FontAwesomeIcon icon={faTrash} /></Button></td>
            </tr>))}
            </tbody>
        </TablaGrilla>
    </>;
}

function cortarUsername(username: string) {
    if (username.includes('@')) {
        return username.split('@')[0];
    }
    return username;
}

function CardTareas(props: {
    tareasCaratula: any[],
    eventoModificar: (item: any) => void,
    eventoCambiarEstado: (item: any) => void,
    eventoSubir: (item: any) => void,
    eventoBajar: (item: any) => void,
    eventoEliminar: (item: any) => void
}) {
    return <Container>
        <div className="card-container-tareas-notas">
            {props?.tareasCaratula?.sort((a: any, b: any) => (a.Orden ?? 0) - (b.Orden ?? 0))?.map((tarea: any) => (
                <div className="card-tarea-nota" style={{ backgroundColor: tarea.Estado === null ? '#edeb58' : 'white' }} onClick={(e: any) => {
                    let element = e.target as HTMLElement;

                    if (element.nodeName !== 'LABEL' && element.nodeName !== 'INPUT') {
                        props.eventoModificar(tarea);
                    }
                }}>
                    <div><p style={{ fontSize: 10 }}>{`${cortarUsername(tarea?.NombreModificadoPor)} - ${toDateTime(tarea.ModificadoEl).toString().split(',')[0]}`}</p></div>
                    <div className="divider" />
                    <p style={{ fontWeight: 'bold', fontSize: 15 }} >{tarea?.Nombre}</p>
                    <div style={{ overflowY: tarea?.Descripcion?.length > 80 ? 'scroll' : 'hidden' }}><p >{tarea?.Descripcion || 'Sin Descripción'}</p> </div>
                    <div className="divider" />

                    <div style={{ display: 'flex', flexDirection: 'row', marginRight: 10, marginBottom: -15 }}>
                        <p><b>{tarea.Estado === null ? '' : 'Estado:'}</b></p><Form.Check style={{ marginRight: 50, display: tarea.Estado === null ? 'none' : '' }} type="switch" id={`estado-${tarea.Id}`} title="Realizado"
                            checked={tarea.Estado === EstadoTareaCaratula.Realizado}
                            onChange={(e: any) => {
                                props.eventoCambiarEstado({
                                    ...tarea,
                                    Estado: e.target.checked ? EstadoTareaCaratula.Realizado : EstadoTareaCaratula.NoRealizado
                                });
                            }} />   <Button variant="link" style={{ marginTop: -10, marginRight: -15 }} onClick={e => {
                                props.eventoEliminar(tarea);
                                e.preventDefault();
                                e.stopPropagation();
                            }}><FontAwesomeIcon icon={faTrash} /></Button>
                    </div>
                </div>))}
        </div>
    </Container>
}

export function TareasCaratula(props: { interno: string, bloquear: boolean, onPlantillaSeleccionada: () => void, tabKey: any }) {
    let bloquear = props.bloquear;
    let formikTareaCaratulaRef = useRef<FormikProps<any>>(null);
    let dialogoEliminarTareaCaratulaRef = useRef<DialogoConfirmarRef>(null);
    let plantillasCaratulaRef = useRef<PlantillasCaratulaRef>(null);
    const [cambiarDiseño, setCambiarDiseño] = useState<boolean>(false)
    let [interno] = useState(props.interno);
    let { mostrarError, userInfo } = useContext(AppContext);
    let api = useApi();
    let [cargando, updateCargando] = useState(true);
    let [mensajeDialogoTarea, updateMensajeDialogoEliminarTarea] = useState('');
    let [estadoTareas, updateEstadoTareas] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'inicializar') {
            return { ...estado, tareas: accion.tareas ?? [], tareasCaratula: accion.tareasCaratula ?? [] };
        } else if (accion.tipo === 'setTareas') {
            return { ...estado, tareas: accion.tareas ?? [] };
        } else if (accion.tipo === 'insertTareaCaratula') {
            let nuevasTareasCaratula = Array.from(estado.tareasCaratula);
            if (!isNullOrWhiteSpace(accion.valor.Id)) {
                nuevasTareasCaratula = nuevasTareasCaratula.filter((r: any) => r.Id !== accion.valor.Id);
            }
            nuevasTareasCaratula.push(accion.valor);
            return {
                ...estado, tareasCaratula: nuevasTareasCaratula,
                modal: EstadoModal.Cerrado, valorModificando: null
            };
        } else if (accion.tipo === 'deleteTareaCaratula') {
            return {
                ...estado,
                tareasCaratula: estado.tareasCaratula.filter((r: any) => r.Id !== accion.valor),
                modal: EstadoModal.Cerrado, valorModificando: null
            };
        } else if (accion.tipo === 'mostrarModalCrear') {
            return { ...estado, modal: EstadoModal.Creando, valorModificando: null, tareaLlevaEstado: false };
        } else if (accion.tipo === 'mostrarModalModificar') {
            return { ...estado, modal: EstadoModal.Modificando, valorModificando: accion.valor, tareaLlevaEstado: accion.valor.Estado !== null && accion.valor.Estado !== undefined };
        } else if (accion.tipo === 'cerrarModal') {
            return { ...estado, modal: EstadoModal.Cerrado, valorModificando: null, tareaLlevaEstado: false };
        } else if (accion.tipo === 'setTareaLlevaEstado') {
            return { ...estado, tareaLlevaEstado: accion.valor };
        }
        return estado;
    }, { tareasCaratula: [], modal: EstadoModal.Cerrado, valorModificando: null, tareaLlevaEstado: false });
    let [estadoModalTiposTarea, updateEstadoModalTiposTarea] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            return { mostrar: true, seleccion: accion.seleccion, valorSeleccionado: null };
        } else if (accion.tipo === 'cerrar') {
            return { mostrar: false, seleccion: false, valorSeleccionado: accion.valor };
        }
        return estado;
    }, { mostrar: false, seleccion: false, valorSeleccionado: null });
    const [filtroTareas, setFiltroTareas] = useState('todas')
    const storage = storageFactory(() => localStorage);
    const onTareasChanged = useCallback((tareas: any[]) => updateEstadoTareas({ tipo: 'setTareas', tareas: tareas }), [updateEstadoTareas]);
    let tareaSchema = Yup.object({
        'Nombre': Yup.string().nullable().required('Debe seleccionar la tarea'),
        'Reportable': Yup.boolean(),
        'Descripcion': Yup.string().nullable(),
        'Estado': Yup.number().nullable().test('estado-requerido', 'Debe seleccionar un estado',
            (valor: any) => {
                if (estadoTareas.tareaLlevaEstado) {
                    return !isNullOrWhiteSpace(valor);
                } else {
                    return true;
                }
            })
    });
    async function cargar(filtroTarea: string) {
        try {
            updateCargando(true);
            let tareasCaratula = await api.getTareasCaratula(interno);
            let tareas = await api.getTareas();

            let itemTareas = storage.getItem('tareasNotasItem')
            let itemTareasDiseño = storage.getItem('tareasNotasItemDiseño')
            setFiltroTareas(itemTareas?.length! > 0 ? itemTareas! : 'todas')
            if (itemTareasDiseño) {
                setCambiarDiseño(itemTareasDiseño === 'false' ? false : true)
            }
            if (filtroTarea === 'todas') {
                updateEstadoTareas({
                    tipo: 'inicializar', tareas: tareas,
                    tareasCaratula: tareasCaratula
                });
            } else if (filtroTarea === 'tareas') {
                updateEstadoTareas({
                    tipo: 'inicializar', tareas: tareas,
                    tareasCaratula: tareasCaratula?.filter((tarea: any) => tarea.Estado !== null)
                });
            } else if (filtroTarea === 'notas') {
                updateEstadoTareas({
                    tipo: 'inicializar', tareas: tareas,
                    tareasCaratula: tareasCaratula?.filter((tarea: any) => tarea.Estado === null)
                });
            }

            updateCargando(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar tareas de carpeta', error);
                mostrarError('Error al cargar tareas de carpeta');
            }
        }
    }

    
        useEffect(() => {
            if(props?.tabKey === 'tareas'){
                const handleKeyDown = (event: { key: string; }) => {
                    if (event.key === 'Insert') {
                      updateEstadoTareas({ tipo: 'mostrarModalCrear' })}
                  };
              
                  window.addEventListener('keydown', handleKeyDown);
              
                  // Cleanup para evitar fugas de memoria
                  return () => {
                    window.removeEventListener('keydown', handleKeyDown);
                  };
            }
              // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [props?.tabKey]);
        
    useEffect(() => {
        cargar('todas');
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (!estadoModalTiposTarea.mostrar && !isNullOrWhiteSpace(estadoModalTiposTarea.valorSeleccionado)) {
            onTareaChange(estadoModalTiposTarea.valorSeleccionado);
        }
        //eslint-disable-next-line
    }, [estadoModalTiposTarea.mostrar]);
    async function submitTarea(values: any, helpers: FormikHelpers<any>) {
        try {
            let puedeModificarCaratula = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeModificarCaratula) {
                let nuevaTarea = {
                    ...values,
                    Id: estadoTareas.valorModificando?.Id,
                    NroClienteAlpha: userInfo.nroClienteAlpha,
                    EmpresaId:  (storage?.getItem('empresaActual') || userInfo.empresaActual),
                    Carpeta: interno
                };
                let exito, error;
                ({ exito, error, tarea: nuevaTarea } = await api.insertTareaCaratula(nuevaTarea));
                await api.eliminarLock(TipoLock.Caratula, interno);
                if (exito) {
                    updateEstadoTareas({ tipo: 'insertTareaCaratula', valor: nuevaTarea });
                } else {
                    mostrarError(error);
                }
            } else {
                if (estadoTareas.modal === EstadoModal.Modificando) {
                    mostrarError('No se puede modificar la tarea porque otro usuario está modificando la caratula');
                } else {
                    mostrarError('No se puede agregar la tarea porque otro usuario está modificando la caratula');
                }
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al insertar tarea a caratula', error);
                if (estadoTareas.modal === EstadoModal.Modificando) {
                    mostrarError('Error al modificar tarea de caratula');
                } else {
                    mostrarError('Error al agregar tarea a caratula');
                }
            }
        }
    }
    async function subirBajarTarea(id: string, tipo: 'subir' | 'bajar') {
        try {
            updateCargando(true);
            let puedeModificarCaratula = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeModificarCaratula) {
                if (tipo === 'bajar') {
                    await api.bajarOrdenTareaCaratula(id);
                } else {
                    await api.subirOrdenTareaCaratula(id);
                }
                await api.eliminarLock(TipoLock.Caratula, interno);
                cargar('todas');
            } else {
                mostrarError('No se puede cambiar el orden de la tarea porque otro usuario está modificando la caratula');
                updateCargando(false);
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cambiar orden de tarea de caratula', error);
                mostrarError('Error al cambiar orden de tarea de caratula');
                updateCargando(false);
            }
        }
    }
    async function modificarTarea(nuevaTarea: any) {
        try {
            updateCargando(true);
            let puedeModificarCaratula = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeModificarCaratula) {
                let exito, error;
                ({ exito, error, tarea: nuevaTarea } = await api.insertTareaCaratula(nuevaTarea));
                await api.eliminarLock(TipoLock.Caratula, interno);
                if (exito) {
                    updateEstadoTareas({ tipo: 'insertTareaCaratula', valor: nuevaTarea });
                } else {
                    mostrarError(error);
                }
            } else {
                mostrarError('No se puede modificar la tarea porque otro usuario está modificando la caratula');
            }
            updateCargando(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al modificar tarea de caratula', error);
                mostrarError('Error al modificar tarea de caratula');
                updateCargando(false);
            }
        }
    }
    function deleteTareaCaratula(item: any) {
        updateMensajeDialogoEliminarTarea('¿Está seguro que desea eliminar esta tarea?');
        dialogoEliminarTareaCaratulaRef.current!.mostrar().then(async () => {
            try {
                updateCargando(true);
                let puedeModificarCaratula = await api.obtenerLock(TipoLock.Caratula, interno);
                if (puedeModificarCaratula) {
                    await api.deleteTareaCaratula(item.Id);
                    await api.eliminarLock(TipoLock.Caratula, interno);
                    updateEstadoTareas({ tipo: 'deleteTareaCaratula', valor: item.Id });
                } else {
                    mostrarError('No se puede eliminar la tarea porque otro usuario está modificando la caratula');
                }
                updateCargando(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar tarea de caratula', error);
                    mostrarError('Error al eliminar tarea de caratula');
                    updateCargando(false);
                }
            }
        }).catch(() => { });
    }
    async function submitSeleccionPlantilla(NombrePlantilla: string) {
        try {
            let puedeModificar = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeModificar) {
                await api.cargarPlantillaEnCaratula(interno, NombrePlantilla, false);
                props.onPlantillaSeleccionada();
                await api.eliminarLock(TipoLock.Caratula, interno);
                await cargar('todas');
            } else {
                mostrarError('No se puede cargar la plantilla porque otro usuario está modificando la caratula');
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar plantilla', error);
                mostrarError('Error al cargar plantilla');
            }
        }
    }
    const onTareaChange = (valor: string) => {
        //modificar todos los campos al mismo tiempo para que se haga bien la validación
        let tarea = estadoTareas.tareas.find((t: any) => t.Nombre === valor);
        if (tarea) {
            const llevaEstado = tarea.LlevaEstado ?? true;
            updateEstadoTareas({ tipo: 'setTareaLlevaEstado', valor: llevaEstado });
        }
        formikTareaCaratulaRef.current?.setValues((valores: any) => ({
            ...valores,
            Nombre: tarea.Nombre,
            Reportable: tarea.ReportablePorDefecto,
            LlevaEstado: tarea.LlevaEstado ?? true,
            Estado: tarea.LlevaEstado ? valores.Estado : null
        }));
    }
    //agregar effect para asegurar que este actualizado el valor tareaLlevaEstado cuando se valida el 
    //formulario del modal tareas luego que se cambie de tarea
    useEffect(() => {
        if (estadoTareas.modal !== EstadoModal.Cerrado) {
            formikTareaCaratulaRef.current?.validateForm();
        }
    }, [estadoTareas.modal, estadoTareas.tareaLlevaEstado]);
    return <div className="mt-2">
        <BlockUi blocking={cargando}>
            <Button className="mb-2 mr-2" disabled={bloquear} onClick={() => updateEstadoTareas({ tipo: 'mostrarModalCrear' })}>
                <FontAwesomeIcon icon={faPlus} />
                <span>Agregar [INSERT]</span>
            </Button>
            <Button className="mb-2 mr-2" onClick={() => updateEstadoModalTiposTarea({ tipo: 'mostrar', seleccion: false })}>
                <span>Tareas</span>
            </Button>
            <Button className="mb-2" onClick={() => plantillasCaratulaRef.current?.mostrar()}>
                <span>Plantillas</span>
            </Button>
            <ButtonGroup className="ml-2" aria-label="Basic example">
                <Button className="mb-2" variant={!cambiarDiseño ? "primary" : "secondary"} onClick={() => {
                    setCambiarDiseño(false)
                    storage.setItem('tareasNotasItemDiseño', 'false')
                }}>Tabla</Button>
                <Button className="mb-2" variant={cambiarDiseño ? "primary" : "secondary"} onClick={() => {
                    setCambiarDiseño(true)
                    storage.setItem('tareasNotasItemDiseño', 'true')
                }} >Cards</Button>
            </ButtonGroup>
            <ButtonGroup className="ml-2" aria-label="Basic example">
                <Button className="mb-2" variant={filtroTareas === 'tareas' ? "primary" : "secondary"} onClick={() => {
                    cargar('tareas')
                    setFiltroTareas('tareas')
                    storage.setItem('tareasNotasItem', "tareas")
                }}>Tareas</Button>
                <Button className="mb-2" variant={filtroTareas === 'notas' ? "primary" : "secondary"} onClick={() => {
                    cargar('notas')
                    setFiltroTareas('notas')
                    storage.setItem('tareasNotasItem', "notas")
                }} >Notas</Button>
                <Button className="mb-2" variant={filtroTareas === 'todas' ? "primary" : "secondary"} onClick={() => {
                    cargar('todas')
                    setFiltroTareas('todas')
                    storage.setItem('tareasNotasItem', "todas")
                }} >Todas</Button>
            </ButtonGroup>
            {!cambiarDiseño ? <GrillaTareasCaratula tareasCaratula={estadoTareas.tareasCaratula}
                eventoModificar={(t: any) => updateEstadoTareas({ tipo: 'mostrarModalModificar', valor: t })}
                eventoCambiarEstado={modificarTarea}
                eventoEliminar={deleteTareaCaratula}
                eventoSubir={(t: any) => subirBajarTarea(t.Id, 'subir')}
                eventoBajar={(t: any) => subirBajarTarea(t.Id, 'bajar')}
            ></GrillaTareasCaratula> : <CardTareas tareasCaratula={estadoTareas?.tareasCaratula}
                eventoModificar={(t: any) => updateEstadoTareas({ tipo: 'mostrarModalModificar', valor: t })}
                eventoCambiarEstado={modificarTarea}
                eventoEliminar={deleteTareaCaratula}
                eventoSubir={(t: any) => subirBajarTarea(t.Id, 'subir')}
                eventoBajar={(t: any) => subirBajarTarea(t.Id, 'bajar')} />}
        </BlockUi>
        <MyModal show={estadoTareas.modal !== EstadoModal.Cerrado} onHide={() => updateEstadoTareas({ tipo: 'cerrarModal' })}>
            <Modal.Dialog size="lg">
                <Modal.Header closeButton>
                    <h2>{estadoTareas.modal === EstadoModal.Modificando ? 'Modificar tarea' : 'Agregar tarea'}</h2>
                </Modal.Header>
                <Formik innerRef={formikTareaCaratulaRef} initialValues={{
                    'Nombre': estadoTareas.valorModificando?.Nombre ?? '',
                    'Descripcion': estadoTareas.valorModificando?.Descripcion ?? '',
                    'Estado': estadoTareas.valorModificando?.Estado,
                    'Reportable': estadoTareas.valorModificando?.Reportable ?? false
                }} validationSchema={tareaSchema} onSubmit={submitTarea}>
                    {({ submitForm, isSubmitting }) => (<>
                        <Modal.Body>
                            <MyForm>
                                <Form.Group>
                                    {estadoTareas.modal === EstadoModal.Modificando ?
                                        <MyFormControl type="text" name="Nombre" label="Tarea" readOnly plaintext></MyFormControl> :
                                        <MySelectConOptionABM name="Nombre" label="Tarea"
                                            options={estadoTareas.tareas.map((t: any) => ({ label: t.Nombre, value: t.Nombre }))}
                                            labelOptionABM="Nueva tarea..."
                                            onSelectABM={() => updateEstadoModalTiposTarea({ tipo: 'mostrar', seleccion: true })}
                                            onValueChange={(option) => {
                                                let valor = option as string | null | undefined;
                                                if (!isNullOrWhiteSpace(valor)) {
                                                    onTareaChange(valor as string);
                                                }
                                            }}></MySelectConOptionABM>}
                                </Form.Group>
                                <Form.Group>
                                    <MyTextarea name="Descripcion" maxLength={250} label="Descripción"></MyTextarea>
                                </Form.Group>
                                <Form.Group>
                                    <MySelect name="Estado" label="Estado" options={estadosDisponibles} isDisabled={!estadoTareas.tareaLlevaEstado}></MySelect>
                                </Form.Group>
                                <Form.Group>
                                    <MyFormCheck name="Reportable" label="Reportable"></MyFormCheck>
                                </Form.Group>
                            </MyForm>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => updateEstadoTareas({ tipo: 'cerrarModal' })}>Cancelar</Button>
                            <Button onClick={submitForm} disabled={isSubmitting}>Ingresar</Button>
                        </Modal.Footer>
                    </>)}
                </Formik>
            </Modal.Dialog>
        </MyModal >
        {/* <MyModal show={mostrarMensajeExito} onHide={() => updateMostrarMensajeExito(false)}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead text-success">Se han guardado los cambios exitosamente</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateMostrarMensajeExito(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal> */}
        <MyModal show={estadoModalTiposTarea.mostrar} onHide={() => updateEstadoModalTiposTarea({ tipo: 'cerrar' })}>
            <Modal.Dialog size="lg">
                <Modal.Header closeButton>
                    Tareas
                </Modal.Header>
                <Modal.Body>
                    <Tareas onTareasChanged={onTareasChanged}
                        onSelectTarea={estadoModalTiposTarea.seleccion ?
                            (tarea: any) => updateEstadoModalTiposTarea({ tipo: 'cerrar', valor: tarea.Nombre }) : undefined}></Tareas>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstadoModalTiposTarea({ tipo: 'cerrar' })}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <PlantillasCaratula ref={plantillasCaratulaRef} onSelectPlantilla={submitSeleccionPlantilla}></PlantillasCaratula>
        <DialogoConfirmar ref={dialogoEliminarTareaCaratulaRef} mensaje={mensajeDialogoTarea} textoBotonConfirmar="Sí" textoBotonCancelar="No"></DialogoConfirmar>
    </div>;
}

