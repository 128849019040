import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef, useState } from "react";
import { Button, Modal, Form, Table, Pagination } from "react-bootstrap";
import { MyModal } from "../../MyModal";
import {
    GrillaRef
} from "../../Grilla";
import BlockUi from "react-block-ui";
import { mostrarError } from "../../App";
import { isNullOrWhiteSpace } from "Utilidades";
import { useApi } from "ApiHooks";
import { CancelToken, useCancel } from "SintiaHooks";

type SelectOption = { value: string, label: string };

export type BusquedaArticulosRef = {
    mostrar: (catalogoInicial: string | null | undefined) => Promise<{ codigoCatalogo: string, codigoArticulo: string }>,
    mostrarSeleccionMultiple: (catalogoInicial: string | null | undefined) => Promise<Array<{ codigoCatalogo: string, codigoArticulo: string }>>,
}

export const BusquedaArticulosSimple = forwardRef((props: any, ref: any) => {
    let api = useApi();
    let { getCancelToken } = useCancel();
    let [cargando, updateCargando] = useState(true);
    let [ncmValue, setncmValue] = useState<string>('');
    let [optionsCatalogos, updateOptionsCatalogos] = useState<SelectOption[]>([]);
    let funcionesPromesa = useRef<any>({ resolve: null, reject: null });
    const [catalogoFocus, setCatalogoFocus] = useState<any>(null)
    const [cantidadItems, setCantidadItems] = useState<number>(0);
    const [paginaActual, setPaginaActual] = useState<number>(1);
    const [catalogoSeleccionado, setCatalogoSeleccionado] = useState<string>('');
    const busquedaInicial = {
        catalogos: [] as string[], codigoArticulo: '', descripcion: '', ncm: '', buscarPorFecha: false,
        fechaDesde: '', fechaHasta: ''
    };
    let [estado, updateEstado] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            let nuevaBusqueda = { ...busquedaInicial };
            nuevaBusqueda.catalogos = isNullOrWhiteSpace(accion.catalogoInicial) ? [] : [accion.catalogoInicial];
            return {
                busqueda: nuevaBusqueda, busquedaActual: nuevaBusqueda, mostrar: true,
                seleccionMultiple: accion.seleccionMultiple
            };
        } else if (accion.tipo === 'cerrar') {
            return { ...estado, mostrar: false };
        } else if (accion.tipo === 'updateBusquedaActual') {
            return { ...estado, busquedaActual: estado.busqueda };
        } else if (accion.tipo === 'updateBusqueda') {
            let nuevaBusqueda = { ...estado.busqueda };
            nuevaBusqueda[accion.propiedad] = accion.valor;
            return { ...estado, busqueda: nuevaBusqueda };
        }
        return estado;
    }, {
        busqueda: { ...busquedaInicial }, busquedaActual: { ...busquedaInicial },
        mostrar: false, seleccionMultiple: false
    });
    let refGrilla = useRef<GrillaRef>(null);
    let cantidadPaginas = Math.ceil(cantidadItems / 50);
    const elementosPorPagina = 50;
    const cerrar = () => {
        updateEstado({ tipo: 'cerrar' });
        funcionesPromesa.current.reject();
    }
    useImperativeHandle(ref, () => ({
        mostrar: (catalogoInicial: string) => {
            return new Promise((resolve, reject) => {
                funcionesPromesa.current = { resolve: resolve, reject: reject };
                updateEstado({ tipo: 'mostrar', catalogoInicial: catalogoInicial, seleccionMultiple: false });
            });
        },
        mostrarSeleccionMultiple: (catalogoInicial: string) => {
            return new Promise((resolve, reject) => {
                funcionesPromesa.current = { resolve: resolve, reject: reject };
                updateEstado({ tipo: 'mostrar', catalogoInicial: catalogoInicial, seleccionMultiple: true });
            });
        }
    }))

    async function cargarDatos(desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await api.busquedaArticulosCompletoPaginado(catalogoSeleccionado?.length > 0 ? [catalogoSeleccionado] : estado.busquedaActual.catalogos,
            estado.busquedaActual.codigoArticulo, estado.busquedaActual.descripcion,
            estado.busquedaActual.ncm, estado.busquedaActual.buscarPorFecha, estado.busquedaActual.fechaDesde,
            estado.busquedaActual.fechaHasta, desde, hasta, cancelToken);
        let paises = await api.getPaises();
        let items = respuesta.Items.map((item: any, index: number) => ({
            ...item,
            NroItem: index,
            Id: item.CodigoCatalogo + '-' + item.CodigoArticulo,
            NombrePaisOrigen: paises.find((pais: any) => pais.Codigo === item.CodigoPaisOrigen)?.Descripcion
        }));
        setDataCatalogo(items);
        setCantidadItems(respuesta?.CantidadTotal)
        return { cantidadItems: respuesta.CantidadTotal, items: items };
    }
    useEffect(() => {
        async function cargarCatalogos() {
            try {
                let respuesta = await api.getCatalogos();
                updateOptionsCatalogos(respuesta.map((catalogo: any) => ({ value: catalogo.Codigo, label: catalogo.Descripcion })));
                updateCargando(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar catalogos', error);
                    mostrarError('Error al cargar los catalogos');
                }
            }
        }
        estado?.busqueda?.catalogos?.length > 0 ? setCatalogoSeleccionado(estado?.busqueda?.catalogos[0]) : setCatalogoSeleccionado('')
        cargarCatalogos();
        cargarDatos(paginaActual, elementosPorPagina, getCancelToken());
        //eslint-disable-next-line
    }, [estado?.mostrar]);
    useEffect(() => {
        refGrilla.current?.recargar();
    }, [estado.busquedaActual]);

    const [dataCatalogo, setDataCatalogo] = useState<any[]>([]);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (dataCatalogo?.length === 0) return;

            if (event.key === "ArrowDown") {
                event.preventDefault();
                setSelectedIndex((prevIndex) => {
                    let newIndex = prevIndex === null ? 0 : prevIndex + 1;
                    setCatalogoFocus(dataCatalogo[newIndex])
                    return newIndex >= dataCatalogo.length ? prevIndex : newIndex;
                });
            }

            if (event.key === "ArrowUp") {
                event.preventDefault();
                setSelectedIndex((prevIndex) => {
                    let newIndex = prevIndex === null ? 0 : prevIndex - 1;
                    setCatalogoFocus(dataCatalogo[newIndex])
                    return newIndex < 0 ? 0 : newIndex;
                });
            }

            if (event.key === "Enter" && selectedIndex !== null && typeof dataCatalogo !== 'string') {
                updateEstado({ tipo: 'cerrar' });
                props?.setCatalogoSelect(catalogoFocus)
                funcionesPromesa.current?.resolve({ codigoCatalogo: catalogoFocus?.CodigoCatalogo, codigoArticulo: catalogoFocus?.CodigoArticulo });
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIndex, dataCatalogo, estado.mostrar]);

    useEffect(() => {
        if (estado?.mostrar) {
            updateEstado({ tipo: 'updateBusqueda', propiedad: 'codigoArticulo', valor: props?.codigoArticulo })
            updateEstado({ tipo: 'updateBusquedaActual' });
        }
    }, [estado?.mostrar, props?.codigoArticulo])

    const rowRefs = useRef<(HTMLTableRowElement | null)[]>([]);

    useEffect(() => {
        if (selectedIndex !== null && rowRefs.current[selectedIndex]) {
            rowRefs.current[selectedIndex].scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIndex]);
    useEffect(() => {
        const desde = (paginaActual - 1) * elementosPorPagina + 1;
        const hasta = paginaActual * elementosPorPagina;

        cargarDatos(desde, hasta, getCancelToken());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginaActual]);

    return (
        <MyModal show={estado.mostrar} onHide={cerrar} style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} >
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    Buscar Articulo
                </Modal.Header>
                <Modal.Body>
                    <BlockUi blocking={cargando}>
                        <Form inline onSubmit={e => {
                            cargarDatos(1, 50, getCancelToken());
                            e.preventDefault();
                        }}>
                            <Form.Group className="my-2">
                                {/* <Form.Label htmlFor="cboCatalogos" className="mx-2">Catalogos</Form.Label> */}
                                {/* {optionsCatalogos?.length > 0 ? (
                                    <SelectCustom
                                        id="cboCatalogos"
                                        options={optionsCatalogos}
                                        placeholder="Seleccionar..."
                                        onChange={(option: SelectOption[] | SelectOption | null | undefined) => {
                                            console.log('option', option)
                                            if (Array.isArray(option)) {
                                                updateEstado({ tipo: 'updateBusqueda', propiedad: 'catalogos', valor: option.map(item => item.value) })
                                            } else if (option === null || option === undefined) {
                                                updateEstado({ tipo: 'updateBusqueda', propiedad: 'catalogos', valor: [] })
                                            } else {
                                                updateEstado({ tipo: 'updateBusqueda', propiedad: 'catalogos', valor: [option.value] })
                                            }
                                        }}
                                        value={optionsCatalogos?.filter((c: any) => estado.busqueda.catalogos?.includes(c.value))}
                                        getOptionLabel={(option: SelectOption) => option.value + ' - ' + option.label}
                                    />
                                ) : (
                                    <p>Cargando opciones...</p>
                                )} */}
                                <Form.Group controlId="cboCatalogos" id="cboCatalogos" className="my-2">
                                    <Form.Label>Catalogos</Form.Label>
                                    <Form.Control as="select" value={estado.busqueda.catalogos} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        setCatalogoSeleccionado(e.target.value)
                                        updateEstado({ tipo: 'updateBusqueda', propiedad: 'catalogos', valor: [e.target.value] })

                                    }}>
                                        {optionsCatalogos?.map((catalogo: any) => (<option key={catalogo.value} value={catalogo.value}>{catalogo.label}</option>))}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Group>
                            <Form.Group className="my-2">
                                <Form.Label htmlFor="txtArticulo" className="mx-2">Codigo Articulo</Form.Label>
                                <Form.Control id="txtArticulo" type="text" value={estado.busqueda.codigoArticulo}
                                    onChange={e => {
                                        updateEstado({ tipo: 'updateBusqueda', propiedad: 'codigoArticulo', valor: e.target.value })
                                        updateEstado({ tipo: 'updateBusquedaActual' });
                                    }}></Form.Control>
                            </Form.Group>
                            <Form.Group className="my-2">
                                <Form.Label htmlFor="txtDescripcion" className="mx-2">Descripción</Form.Label>
                                <Form.Control id="txtDescripcion" type="text" value={estado.busqueda.descripcion}
                                    onChange={e => {
                                        updateEstado({ tipo: 'updateBusqueda', propiedad: 'descripcion', valor: e.target.value })
                                        updateEstado({ tipo: 'updateBusquedaActual' });
                                    }}></Form.Control>
                            </Form.Group>
                            <Form.Group className="my-2">
                                <Form.Label htmlFor="txtNcm" className="mx-2">Ncm</Form.Label>
                                <Form.Control style={{ borderColor: ncmValue?.length < 4 && ncmValue?.length !== 0 && ncmValue !== '' ? 'red' : 'black' }} maxLength={15} id="txtNcm" type="text"
                                    onChange={(e) => {
                                        setncmValue(e.target.value)
                                        updateEstado({ tipo: 'updateBusqueda', propiedad: 'ncm', valor: e.target.value })
                                        updateEstado({ tipo: 'updateBusquedaActual' });
                                    }}></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Check custom className="mx-2" label="Buscar por fecha modificación" id="checkBuscarPorFecha" checked={estado.busqueda.buscarPorFecha}
                                    onChange={(e: any) => {
                                        updateEstado({ tipo: 'updateBusqueda', propiedad: 'buscarPorFecha', valor: e.target.checked })
                                        updateEstado({ tipo: 'updateBusquedaActual' });
                                    }}></Form.Check>
                                <Form.Label htmlFor="txtFechaDesde" className="mx-2">Desde</Form.Label>
                                <Form.Control type="date" id="txtFechaDesde" disabled={!estado.busqueda.buscarPorFecha} value={estado.busqueda.fechaDesde} className="mb-2 mr-2"
                                    onChange={e => {
                                        updateEstado({ tipo: 'updateBusqueda', propiedad: 'fechaDesde', valor: e.target.value })
                                        updateEstado({ tipo: 'updateBusquedaActual' });
                                    }}></Form.Control>
                                <Form.Label htmlFor="txtFechaHasta" className="mr-2">Hasta</Form.Label>
                                <Form.Control type="date" id="txtFechaHasta" disabled={!estado.busqueda.buscarPorFecha} value={estado.busqueda.fechaHasta} className="mb-2 mr-2"
                                    onChange={e => {
                                        updateEstado({ tipo: 'updateBusqueda', propiedad: 'fechaHasta', valor: e.target.value })
                                        updateEstado({ tipo: 'updateBusquedaActual' });
                                    }
                                    }></Form.Control>
                            </Form.Group>
                            <Button type="submit" className="mb-2" disabled={ncmValue?.length < 4 && ncmValue?.length !== 0 && ncmValue !== ''}>Buscar</Button>
                        </Form>
                    </BlockUi>
                    {ncmValue?.length < 4 && ncmValue?.length !== 0 && ncmValue !== '' && (
                        <div style={{ marginLeft: 50 }}>
                            <p style={{ color: 'red', fontSize: 15 }}>Ncm minimo 4 digitos </p>
                        </div>
                    )}
                    {!estado.seleccionMultiple && <p>Haga click en un artículo para seleccionarlo</p>}
                    {/* <Grilla responsiva campos={campos} cargarDatos={cargarDatos} nroItemSeleccionado={1} habilitarFoco 
                    eventoDetalle={estado.seleccionMultiple ? undefined : item => {
                        updateEstado({ tipo: 'cerrar' });
                        funcionesPromesa.current?.resolve({ codigoCatalogo: item.CodigoCatalogo, codigoArticulo: item.CodigoArticulo });
                    }} ref={refGrilla} seleccionMultiple={estado.seleccionMultiple} /> */}
                    {dataCatalogo?.length > 0 &&
                        <div style={{ maxHeight: dataCatalogo.length > 20 ? "600px" : "auto", overflowY: dataCatalogo.length > 20 ? "auto" : "visible" }}>
                            <Table striped bordered hover variant="light">
                                <thead>
                                    <tr>
                                        <th>Catalogo</th>
                                        <th>Nro.Articulo</th>
                                        <th>NCM</th>
                                        <th>Descripcion</th>
                                        <th>Sufijos</th>
                                        {/* <th>Acción</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataCatalogo.map((catalogo, index) => (
                                        <tr
                                            key={index}
                                            ref={(el) => (rowRefs.current[index] = el)}
                                            style={{
                                                backgroundColor:
                                                    selectedIndex === index
                                                        ? "#1a59ed"
                                                        : index % 2 === 0
                                                            ? "#f2f2f2"
                                                            : "#d3d3d3",
                                                color: selectedIndex === index ? "white" : "black",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                updateEstado({ tipo: 'cerrar' });
                                                props?.setCatalogoSelect(catalogo);
                                                funcionesPromesa.current?.resolve({ codigoCatalogo: catalogo.CodigoCatalogo, codigoArticulo: catalogo.CodigoArticulo });
                                            }}
                                        >
                                            <td>{catalogo?.CodigoCatalogo}</td>
                                            <td>{catalogo?.CodigoArticulo}</td>
                                            <td>{catalogo.Ncm}</td>
                                            <td>{catalogo?.Descripcion}</td>
                                            <td>{catalogo?.Sufijos}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <div className="d-flex justify-content-center">
                                <Pagination>
                                    <Pagination.First
                                        style={{ margin: 0, padding: 0 }}
                                        disabled={paginaActual === 1}
                                        onClick={() => setPaginaActual(1)}
                                    />
                                    <Pagination.Prev
                                        disabled={paginaActual === 1}
                                        onClick={() => setPaginaActual(paginaActual - 1)}
                                    />

                                    <Pagination.Item
                                        active={paginaActual === 1}
                                        key={1}
                                        onClick={() => setPaginaActual(1)}
                                    >
                                        1
                                    </Pagination.Item>

                                    {cantidadPaginas <= 6 ? (
                                        Array.from({ length: cantidadPaginas - 1 }, (_, index) => {
                                            let pageNum = index + 2;
                                            return (
                                                <Pagination.Item
                                                    active={paginaActual === pageNum}
                                                    key={pageNum}
                                                    onClick={() => setPaginaActual(pageNum)}
                                                >
                                                    {pageNum}
                                                </Pagination.Item>
                                            );
                                        })
                                    ) : (
                                        <>
                                            <Pagination.Item
                                                active={paginaActual === 2}
                                                key={2}
                                                onClick={() => setPaginaActual(2)}
                                            >
                                                2
                                            </Pagination.Item>
                                            <Pagination.Item
                                                active={paginaActual === 3}
                                                key={3}
                                                onClick={() => setPaginaActual(3)}
                                            >
                                                3
                                            </Pagination.Item>

                                            {paginaActual > 4 && <Pagination.Ellipsis />}
                                            {paginaActual > 3 && paginaActual < cantidadPaginas - 2 && (
                                                <Pagination.Item
                                                    active
                                                    key={paginaActual}
                                                    onClick={() => setPaginaActual(paginaActual)}
                                                >
                                                    {paginaActual}
                                                </Pagination.Item>
                                            )}
                                            {paginaActual < cantidadPaginas - 3 && <Pagination.Ellipsis />}

                                            <Pagination.Item
                                                active={paginaActual === cantidadPaginas - 2}
                                                key={cantidadPaginas - 2}
                                                onClick={() => setPaginaActual(cantidadPaginas - 2)}
                                            >
                                                {cantidadPaginas - 2}
                                            </Pagination.Item>
                                            <Pagination.Item
                                                active={paginaActual === cantidadPaginas - 1}
                                                key={cantidadPaginas - 1}
                                                onClick={() => setPaginaActual(cantidadPaginas - 1)}
                                            >
                                                {cantidadPaginas - 1}
                                            </Pagination.Item>
                                            <Pagination.Item
                                                active={paginaActual === cantidadPaginas}
                                                key={cantidadPaginas}
                                                onClick={() => setPaginaActual(cantidadPaginas)}
                                            >
                                                {cantidadPaginas}
                                            </Pagination.Item>
                                        </>
                                    )}

                                    <Pagination.Next
                                        disabled={paginaActual === cantidadPaginas}
                                        onClick={() => setPaginaActual(paginaActual + 1)}
                                    />
                                    <Pagination.Last
                                        disabled={paginaActual === cantidadPaginas}
                                        onClick={() => setPaginaActual(cantidadPaginas)}
                                    />
                                </Pagination>
                            </div>

                        </div>}
                </Modal.Body>
                <Modal.Footer>
                    {estado.seleccionMultiple && <Button onClick={() => {
                        let articulos = refGrilla.current?.getClavesSeleccionadas()?.map((id: string) => {
                            let [codigoCatalogo, ...partes] = id.split('-');
                            return { codigoCatalogo, codigoArticulo: partes.join('-') };
                        });
                        updateEstado({ tipo: 'cerrar' });
                        funcionesPromesa.current?.resolve(articulos);
                    }}>Ingresar Articulos Seleccionados</Button>}
                    <Button variant="danger" onClick={cerrar}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal >)
});