import { Formik, Field, FormikProps, FormikHelpers } from "formik";
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Alert, Tabs, Tab, Form, Col, Button, Modal, Badge, Container } from "react-bootstrap";
import { AppContext } from "../../App";
import { MyForm, MyFormControl, MyAsyncSelect, MyAsyncSelectRef, OnValueChangeArgument, MySelect, SelectOption, MyTextarea, MySelectConOptionABM } from "../../FormikHooks";
import { IngresoSufijos, IngresoSufijosRef } from "../../IngresoSufijos";
import { useDecodedParams, convertirDatosGenericosAOption, optionLabelConCodigo, isNullOrWhiteSpace, toFixedDecimal } from "../../Utilidades";
import * as Yup from 'yup';
import { Switch } from "react-router";
import { Link } from "react-router-dom";
import { useApi, TipoNcm, TipoLock } from "ApiHooks";
import Decimal from "decimal.js";
import { DateTime } from "luxon";
import { BusquedaArticulosRef } from "./BusquedaArticulos";
import styled from "styled-components";
import InnerRouter from "InnerRouter";
import { MyModal } from "MyModal";
import ProtectedRoute from 'ProtectedRoute';
import { CatalogosSimple } from "Paginas/Catalogos/CatalogosSimple";
import { CrearEditarCatalogo } from "Paginas/Catalogos/CrearEditarCatalogo";
import { storageFactory } from 'storage-factory';
import BlockUi from "react-block-ui";
import ConfiguracionStorage from "ConfiguracionStorage";
import './styleSubItem.css'
import { BusquedaArticulosSimple } from "Paginas/Caratulas/BusquedaArticulosSimple";

const ModalCargaSimpleSubItem = ({ show, handleClose, setShowModalCargaSimple }: any) => {

    function enCeroSiEsVacio(valor: string | null | undefined) {
        return isNullOrWhiteSpace(valor) ? '0' : valor;
    }

    enum EstadoCargando {
        CargaInicial,
        Cargando,
        Listo
    }

    const StyledFormCheck = styled(Form.Check)`
        margin-bottom:.5rem;
    `;

    const storage = storageFactory(() => localStorage);
    const claveUltimoCatalogo = 'ultimoCatalogoSubitems';
    const claveUltimoArticulo = 'ultimoArticuloSubitems';
    const claveMantenerDatosSubitems = 'mantenerDatosSubitems';

    let selectNcmRef = useRef<MyAsyncSelectRef>(null);
    let codigoAfipRef = useRef<MyAsyncSelectRef>(null);
    let codigoUnidadDeclaradaRef = useRef<any>(null);
    let descripcionRef = useRef<HTMLElement>(null);
    let IdArticuloRef = useRef<HTMLInputElement>(null)
    let descripcionSediRef = useRef<HTMLElement>(null)
    let kgNetoUnitarioRef = useRef<HTMLElement>(null)
    let cantidadDeclaradaRef = useRef<HTMLElement>(null)
    let formikRef = useRef<FormikProps<any>>(null);
    let sufijosRef = useRef<IngresoSufijosRef>(null);
    let fillerRef = useRef<HTMLSelectElement>(null);
    let sufijosRefNew = useRef<HTMLElement>(null)
    let busquedaArticulosRef = useRef<BusquedaArticulosRef>(null);
    let { id, interno } = useDecodedParams() as { interno: string, id?: string };
    let { mostrarError, userInfo } = useContext(AppContext);
    let api = useApi();
    let [cargando, updateCargando] = useState(EstadoCargando.CargaInicial);
    let [mensajeErrorAlert, updateMensajeErrorAlert] = useState('');
    let [caratula, updateCaratula] = useState<any>({});
    let subitem = useRef<any>(null);
    let ncmValidado = useRef<any>(null);
    let [subregimen, updateSubregimen] = useState<any>(null);
    let [estadoModalCatalogos, updateEstadoModalCatalogos] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            return { abierto: true, valorSeleccionado: null };
        } else if (accion.tipo === 'cerrar') {
            return { abierto: false, valorSeleccionado: accion.valor };
        }
        return estado;
    }, { abierto: false, valorSeleccionado: null });
    let [optionsCatalogos, updateOptionsCatalogos] = useState<SelectOption[]>([]);
    // let [optionsFacturas, updateOptionsFacturas] = useState<SelectOption[]>([]);
    let [optionsCodigoProrrateo, updateOptionsCodigoProrrateo] = useState<SelectOption[]>([]);
    let [tieneLna, updateTieneLna] = useState(false);
    let [tieneValorCriterio, updateTieneValorCriterio] = useState(false);
    let [tieneEstampilla, updateTieneEstampilla] = useState(false);
    let [labelUnidadEstadistica, updateLabelUnidadEstadistica] = useState('');
    const [codigoUnidadDeclaradaValue, setValueCodigoUnidadDeclarada] = useState('');
    const [mostrarErrores, setMostrarErrores] = useState(false);
    // let [cancelaciones, updateCancelaciones] = useState<any[]>([]);
    // let [estadoIntervenciones, updateEstadoIntervenciones] = useReducer((estado: any, accion: any) => {
    //     if (accion.tipo === 'setIntervencionesNcm') {
    //         let nuevasIntervenciones = accion.valor?.map((int: string) => ({ Descripcion: int })) ?? [];
    //         nuevasIntervenciones = nuevasIntervenciones.concat(estado.intervenciones.filter((int: any) => !isNullOrWhiteSpace(int.Id)));
    //         return { intervenciones: nuevasIntervenciones.map((int: any, indice: number) => ({ ...int, Indice: indice })) };
    //     } else if (accion.tipo === 'setIntervencionesArticulo') {
    //         let nuevasIntervenciones = estado.intervenciones.filter((int: any) => isNullOrWhiteSpace(int.Id));
    //         if (accion.valor?.length > 0) {
    //             nuevasIntervenciones = nuevasIntervenciones.concat(accion.valor);
    //         }
    //         return { intervenciones: nuevasIntervenciones.map((int: any, indice: number) => ({ ...int, Indice: indice })) };
    //     }
    //     return { ...estado };
    // }, { intervenciones: [] });
    let [deshabilitarPrecioUnitario, updateDeshabilitarPrecioUnitario] = useState(true);
    // const [continuarSufijosFormEnter, setContinuarSufijosFormEnter] = useState(false)
    const [IdArticuloValue, setIdArticuloValue] = useState('')
    const [mensajeArticuloNoEncontrado, setMensajeArticuloNoEncontrado] = useState('')
    const [key, setKey] = useState("tab1");
    const [catalogoSelect, setCatalogoSelect] = useState<any>()
    const [erroresValidacion, setErroresValidacion] = useState('')

    // let camposCancelaciones = [{ propiedad: 'Id', visible: false, clave: true }, { propiedad: 'Dit', titulo: 'Dit' },
    // { titulo: 'Ncm', propiedad: 'Ncm' }, { titulo: 'Expctit', propiedad: 'Expctit' },
    // { titulo: 'Nro Item', propiedad: 'NroItemCancelado' }, { titulo: 'Nro Subitem', propiedad: 'NroSubitemCancelado' },
    // { titulo: 'Cantidad', propiedad: 'Cantidad' }, { titulo: 'Factor Ponderación', propiedad: 'FactorPonderacion' }]
    let cambiandoValores = useRef<boolean>(false);
    let schema = useMemo(() => {
        return Yup.object({
            'ForzarSufijos': Yup.boolean(),
            'IdCatalogo': Yup.string().nullable(),
            'IdArticulo': Yup.string().nullable(),
            'CantidadEstadistica': Yup.number().nullable()
                .typeError('Debe ingresar un número en Cnatidad Estadistica')
                .test('igual-a-cant-declarada', 'La cantidad estadistica debe ser igual a la cantidad declarada si las dos unidades son iguales', function (valor) {
                    if (valor === null || valor === undefined) {
                        return true;
                    }
                    const unidadEstadistica = this.resolve(Yup.ref('CodigoUnidadEstadistica'));
                    const unidadDeclarada = this.resolve(Yup.ref('CodigoUnidadDeclarada'));
                    const cantidadDeclarada = this.resolve(Yup.ref('CantidadDeclarada'));
                    if (!isNullOrWhiteSpace(unidadEstadistica) && !isNullOrWhiteSpace(unidadDeclarada)
                        && unidadEstadistica === unidadDeclarada
                        && cantidadDeclarada !== null && cantidadDeclarada !== undefined
                        && cantidadDeclarada !== valor) {
                        return false;
                    }
                    return true;
                }),
            'AjusteIncluir': Yup.number().nullable().typeError('Debe ingresar un número en Ajuste a Incluir'),
            'AjusteDeducir': Yup.number().nullable().typeError('Debe ingresar un número en Ajuste a Deducir'),
            'CantidadDeclarada': Yup.number().nullable().typeError('Debe ingresar un número en Cantidad Declarada')
                .required('Debe ingresar la cantidad declarada'),
            'PrecioUnitario': Yup.number().nullable().typeError('Debe ingresar un número en Precio Unitario')
                .required('Debe ingresar el precio unitario')
                .min(0, 'El precio unitario debe ser mayor a cero'),
            'TotalFob': Yup.number().nullable().typeError('Debe ingresar un número en Total')
                .required('Debe ingresar el fob total')
                .min(0, 'El fob total debe ser mayor a cero'),
            'CodigoUnidadEstadistica': Yup.string().nullable(),
            'Descripcion': Yup.string().nullable(),
            'DescripcionMaria': Yup.string().nullable(),
            'Ncm': Yup.string().nullable().required('Debe ingresar el NCM'),
            'CodigoUnidadDeclarada': Yup.string().nullable().required('Debe ingresar la unidad declarada'),
            'CodigoPaisOrigen': Yup.string().nullable(),
            'Sufijos': Yup.string().nullable(),
            'CodigoEstadoMercaderia': Yup.string().nullable(),
            'KgNetoUnitario': Yup.number().nullable().typeError('Debe ingresar un número en Kg Neto Unitario')
                .min(0, 'El kg neto unitario debe ser mayor o igual a 0'),
            'KgNeto': Yup.number().nullable().typeError('Debe ingresar un número en Kg Neto')
                .min(0, 'El kg neto debe ser mayor o igual a 0')
                .test('requerido', 'Debe ingresar un valor cuando la unidad estadistica es 01 y hay cantidad estadistica', function (valor) {
                    if (valor === null || valor === undefined) {
                        const unidadEstadistica = this.resolve(Yup.ref('CodigoUnidadEstadistica'));
                        const cantidadEstadistica = this.resolve(Yup.ref('CantidadEstadistica'));
                        return !(unidadEstadistica === '01' && cantidadEstadistica !== null && cantidadEstadistica !== undefined);
                    }
                    return true;
                }).test('kg-neto-igual-a-cant-estadistica', 'El kg neto debe ser igual a la cantidad estadistica cuando la unidad estadistica es KILOGRAMO', function (valor) {
                    if (valor === null || valor === undefined) {
                        return true;
                    }
                    const unidadEstadistica = this.resolve(Yup.ref('CodigoUnidadEstadistica'));
                    const cantidadEstadistica = this.resolve(Yup.ref('CantidadEstadistica'));
                    if (unidadEstadistica === '01' && cantidadEstadistica !== null && cantidadEstadistica !== undefined
                        && cantidadEstadistica !== valor) {
                        return false;
                    }
                    return true;
                }),
            'KgBruto': Yup.number().nullable().typeError('Debe ingresar un número')
                .required('Debe ingresar el kg bruto')
                .min(0, 'El kg bruto debe ser mayor o igual a 0'),
            'Filler': Yup.string().nullable(),
            'Lista': Yup.string().nullable(),
            'CodigoEAN': Yup.string().nullable(),
            'CodigoProrrateo': Yup.string().nullable(),
            'InsumosAConsumo': Yup.number().nullable().typeError('Debe ingresar un número')
                .required('Debe ingresar los insumos a consumo'),
            'InsumosTemporales': Yup.number().nullable().typeError('Debe ingresar un número')
                .required('Debe ingresar los insumos temporales'),
            'NroFactura': Yup.string().nullable(),
            'CodigoAfip': Yup.string().nullable().test('requerido', 'Debe ingresar el codigo afip en un subregimen particular', function (valor) {
                if (subregimen?.Particular) {
                    return !isNullOrWhiteSpace(valor);
                }
                return true;
            }),
            // 'BienUso': Yup.string().nullable(),
            'Otros': Yup.string().nullable(),
            'Naladisa': Yup.string().nullable(),
        });
    }, [subregimen]);
    async function cargarUnidadEstadistica(codigoUnidad: string) {
        let unidades = await api.getUnidades();
        let unidad = unidades.find((item: any) => item.Codigo === codigoUnidad);
        if (unidad) {
            updateLabelUnidadEstadistica(unidad.Codigo + ' - ' + unidad.Descripcion);
        } else {
            updateLabelUnidadEstadistica(codigoUnidad);
        }
    }

    useEffect(() => {
        if (catalogoSelect !== undefined && catalogoSelect?.Ncm !== '' && codigoUnidadDeclaradaValue?.length > 0) {
            handleNextTab()
            setTimeout(() => {
                codigoUnidadDeclaradaRef?.current?.focus()
            }, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalogoSelect])

    async function existeValorCriterio(ncm: string, codigoPaisOrigen: string) {
        try {
            let respuesta = await api.getValorCriterio(ncm, codigoPaisOrigen);
            return !!respuesta;
        } catch (error) {
            if (api.isCancel(error)) {
                throw error;
            } else {
                console.error('Error al obtener valor criterio', error);
                return false;
            }
        }
    }
    async function cargarDatosNcm(ncm: string) {
        try {
            let ncmValidado = await api.validarNcmArreglandoPuntos(ncm);
            if (ncmValidado === null || ncmValidado === undefined) {
                updateTieneLna(false);
                updateTieneValorCriterio(false);
                updateTieneEstampilla(false);
                formikRef.current?.setFieldValue('CodigoUnidadEstadistica', '', false);
                updateLabelUnidadEstadistica('');
            } else {
                formikRef.current?.setFieldValue('Ncm', ncmValidado.Ncm.POSICION_ARANCELARIA, true);
                formikRef.current?.setFieldValue('CodigoUnidadEstadistica', ncmValidado.Ncm.m012, false);
                //javier pidió que se cargue el kg neto si cambia el ncm y la nueva unidad estadistica es kg
                if (ncmValidado.Ncm.m012 === '01') {
                    formikRef.current?.setFieldValue('KgNeto', formikRef.current?.values?.CantidadEstadistica, false);
                }
                // updateEstadoIntervenciones({ tipo: 'setIntervencionesNcm', valor: ncmValidado.Intervenciones });
                await cargarUnidadEstadistica(ncmValidado.Ncm.m012);
                updateTieneLna(!!ncmValidado.LNA);
                updateTieneEstampilla(ncmValidado.Ncm.m042 === '*');
                let paisOrigen = formikRef.current?.values?.CodigoPaisOrigen;
                if (isNullOrWhiteSpace(paisOrigen)) {
                    updateTieneValorCriterio(false);
                } else {
                    let existe = await existeValorCriterio(ncm, paisOrigen);
                    updateTieneValorCriterio(existe);
                }
            }
        } catch (error) {
            if (api.isCancel(error)) {
                throw error;
            } else {
                console.error('Error al validar NCM', error);
                // mostrarError('Error al validar NCM');
            }
        }
    }
    async function cargarDatosArticulo(IdCatalogo: string, IdArticulo: string) {
        updateCargando(EstadoCargando.Cargando);
        setIdArticuloValue(IdArticulo?.trimEnd())
        try {
            let respuesta = await api.getArticulo(IdCatalogo, IdArticulo?.trimEnd());
            if (respuesta) {
                setMensajeArticuloNoEncontrado('')
                setIdArticuloValue(IdArticulo?.trimEnd())
                formikRef.current?.setFieldValue('IdArticulo', IdArticulo?.trimEnd())
                formikRef.current?.setFieldValue('Otros', respuesta.Articulo.Otros);
                formikRef.current?.setFieldValue('Ncm', respuesta.Articulo.Ncm);
                formikRef.current?.setFieldValue('Descripcion', respuesta.Articulo.Descripcion);
                if (subregimen?.Codigo === 'SIMI' || subregimen?.Codigo === 'SIRA' || subregimen?.Codigo === 'SEDI') {
                    formikRef.current?.setFieldValue('DescripcionMaria', respuesta.Articulo.DescripcionMaria);
                }
                formikRef.current?.setFieldValue('CodigoPaisOrigen', respuesta.Articulo.CodigoPaisOrigen);
                formikRef.current?.setFieldValue('Naladisa', respuesta.Articulo.Naladisa);
                formikRef.current?.setFieldValue('PrecioUnitario', respuesta.Articulo.PrecioUnitario);
                formikRef.current?.setFieldValue('KgNetoUnitario', respuesta.Articulo.KgNetoUnitario);
                formikRef.current?.setFieldValue('CodigoEstadoMercaderia', respuesta.Articulo.CodigoEstadoMercaderia);
                if (respuesta.Articulo.CodigoUnidadDeclarada?.length > 0) {
                    formikRef.current?.setFieldValue('CodigoUnidadDeclarada', respuesta.Articulo.CodigoUnidadDeclarada);
                    setValueCodigoUnidadDeclarada(respuesta.Articulo.CodigoUnidadDeclarada)
                    setKey("tab2")
                    setTimeout(() => {
                        return cantidadDeclaradaRef?.current?.focus()
                    }, 500);
                }else{
                    setKey("tab2")
                    setTimeout(() => {
                        return selectNcmRef.current?.focus();
                    }, 500);
                }
                formikRef.current?.setFieldValue('Filler', respuesta.Articulo.Filler);
                formikRef.current?.setFieldValue('Sufijos', respuesta.Articulo.Sufijos);
                // updateEstadoIntervenciones({ tipo: 'setIntervencionesArticulo', valor: respuesta.Intervenciones });
                await cargarDatosNcm(respuesta.Articulo.Ncm);
                updateCargando(EstadoCargando.Listo);
            } else {
                formikRef.current?.setFieldValue('Descripcion', '');
                formikRef.current?.setFieldValue('Ncm', '');
                formikRef.current?.setFieldValue('CodigoUnidadDeclarada', '');
                formikRef.current?.setFieldValue('CodigoUnidadEstadistica', '');
                formikRef.current?.setFieldValue('CalcularPrecioUnitario', '');
                formikRef.current?.setFieldValue('Sufijos', '');
                setMensajeArticuloNoEncontrado('No se encontró un articulo con el código ingresado')
                updateCargando(EstadoCargando.Listo);
                descripcionRef.current?.focus();
            }
        } catch (error) {
            setMensajeArticuloNoEncontrado('No se encontró un articulo con el código ingresado')
            if (!api.isCancel(error)) {
                setMensajeArticuloNoEncontrado('No se encontró un articulo con el código ingresado')
                console.error('Error al buscar articulo', error);
                // mostrarError('Error al buscar articulo');
                updateCargando(EstadoCargando.Listo);
                descripcionRef.current?.focus();
            }
        }
    }
    async function codigoPaisOrigenOnChanged(paisOrigen: string) {
        try {
            let ncm = formikRef.current?.values?.Ncm;
            if (isNullOrWhiteSpace(paisOrigen) || isNullOrWhiteSpace(ncm)) {
                updateTieneValorCriterio(false);
            } else {
                let ncmValidado = await api.validarNcmArreglandoPuntos(ncm);
                if (ncmValidado) {
                    formikRef.current?.setFieldValue('Ncm', ncmValidado.Ncm.POSICION_ARANCELARIA);
                    let existe = await existeValorCriterio(ncmValidado.Ncm.POSICION_ARANCELARIA, paisOrigen);
                    updateTieneValorCriterio(existe);
                } else {
                    updateTieneValorCriterio(false);
                }
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al validar NCM', error);
                // mostrarError('Error al validar NCM');
            }
        }
    }

    function cantidadDeclaradaOnChanged(valor: string) {
        /* comente estas lineas porque se resuelve el comportamient de cantidad declarada en 
        /* la linea 349: if (cargando == EstadoCargando.Listo && !cambiandoValores.current)
        /* antes no entraba porque verificaba !cargandom cargando es un enum, tenia que comprar con EstadoCargando.Listo
        if (formikRef?.current?.values?.CodigoUnidadDeclarada === formikRef.current?.values?.CodigoUnidadEstadistica) {
            formikRef?.current?.setFieldValue('CantidadDeclarada', valor)
            formikRef?.current?.setFieldValue('CantidadEstadistica', valor)
        }
        if(formikRef?.current?.values?.CodigoUnidadDeclarada.toString() === '01' ){
            formikRef?.current?.setFieldValue('KgNeto', toFixedDecimal(valor, 2))
        }
        if (formikRef?.current?.values?.CodigoUnidadDeclarada.toString() === '01' && formikRef.current?.values?.CodigoUnidadEstadistica.toString() === '01') {
            formikRef?.current?.setFieldValue('KgNeto', toFixedDecimal(valor, 2))
        }*/

        if (cargando === EstadoCargando.Listo && !cambiandoValores.current) {

            let unidadDeclarada = formikRef.current?.values?.CodigoUnidadDeclarada;
            let unidadEstadistica = formikRef.current?.values?.CodigoUnidadEstadistica;

            const conversorUnidadDeclarada = (valor: string, unidadDeclarada: string, unidadEstadistica: string) => {
                // KILOGRAMO <-> MILIGRAMO
                if ((unidadDeclarada === '01' && unidadEstadistica === '41') ||
                    (unidadDeclarada === '41' && unidadEstadistica === '01')) {
                    return unidadDeclarada === '01' ? Number(valor) * 1000000 : Number(valor) / 1000000;

                    // KILOGRAMO <-> GRAMO
                } else if ((unidadDeclarada === '01' && unidadEstadistica === '14') ||
                    (unidadDeclarada === '14' && unidadEstadistica === '01')) {
                    return unidadDeclarada === '01' ? Number(valor) * 1000 : Number(valor) / 1000;

                    // GRAMO <-> MILIGRAMO
                } else if ((unidadDeclarada === '14' && unidadEstadistica === '41') ||
                    (unidadDeclarada === '41' && unidadEstadistica === '14')) {
                    return unidadDeclarada === '14' ? Number(valor) * 1000 : Number(valor) / 1000;

                    // KILÓMETROS <-> METROS
                } else if ((unidadDeclarada === '17' && unidadEstadistica === '02') ||
                    (unidadDeclarada === '02' && unidadEstadistica === '17')) {
                    return unidadDeclarada === '17' ? Number(valor) * 1000 : Number(valor) / 1000;

                    // TONELADAS <-> KILOGRAMOS
                } else if ((unidadDeclarada === '29' && unidadEstadistica === '01') ||
                    (unidadDeclarada === '01' && unidadEstadistica === '29')) {
                    return unidadDeclarada === '29' ? Number(valor) * 1000 : Number(valor) / 1000;

                    // DOCENAS <-> UNIDADES
                } else if ((unidadDeclarada === '09' && unidadEstadistica === '07') ||
                    (unidadDeclarada === '07' && unidadEstadistica === '09')) {
                    return unidadDeclarada === '09' ? Number(valor) * 12 : Number(valor) / 12;

                    // GRAMOS <-> TONELADAS
                } else if ((unidadDeclarada === '14' && unidadEstadistica === '29') ||
                    (unidadDeclarada === '29' && unidadEstadistica === '14')) {
                    return unidadDeclarada === '14' ? Number(valor) / 1000000 : Number(valor) * 1000000;

                    // UNIDADES <-> PARES
                } else if ((unidadDeclarada === '07' && unidadEstadistica === '08') ||
                    (unidadDeclarada === '08' && unidadEstadistica === '07')) {
                    return unidadDeclarada === '07' ? Number(valor) / 2 : Number(valor) * 2;

                    // GRUESA <-> UNIDAD (1 gruesa = 144 unidades)
                } else if ((unidadDeclarada === '54' && unidadEstadistica === '07') ||
                    (unidadDeclarada === '07' && unidadEstadistica === '54')) {
                    return unidadDeclarada === '54' ? Number(valor) * 144 : Number(valor) / 144;

                    // DOCENA <-> GRUESA (1 gruesa = 12 docenas)
                } else if ((unidadDeclarada === '09' && unidadEstadistica === '54') ||
                    (unidadDeclarada === '54' && unidadEstadistica === '09')) {
                    return unidadDeclarada === '09' ? Number(valor) / 12 : Number(valor) * 12;
                }

                // Si no coincide con ningún caso esperado, retorna null
                return null;
            };

            let newValueConversor = conversorUnidadDeclarada(valor, unidadDeclarada, unidadEstadistica)
            formikRef.current?.setFieldValue('CantidadEstadistica', newValueConversor);
            if (unidadEstadistica === '01') {
                formikRef.current?.setFieldValue('KgNeto', newValueConversor);
            }
            try {
                if (isNullOrWhiteSpace(valor)) {
                    cambiandoValores.current = true;

                    if (unidadDeclarada === unidadEstadistica) {
                        formikRef.current?.setFieldValue('CantidadEstadistica', '');
                        updateLabelUnidadEstadistica('');
                    }
                    if (unidadDeclarada === '01') {
                        formikRef.current?.setFieldValue('KgNeto', '');
                    }
                    cambiandoValores.current = false;
                } else {
                    cambiandoValores.current = true;
                    if (unidadDeclarada === unidadEstadistica) {
                        formikRef.current?.setFieldValue('CantidadEstadistica', toFixedDecimal(valor, 2));
                    }
                    if (unidadDeclarada === '01') {
                        formikRef.current?.setFieldValue('KgNeto', toFixedDecimal(valor, 2));
                    } else {
                        try {
                            let kgNetoUnitario = new Decimal(formikRef.current?.values?.KgNetoUnitario);
                            if (kgNetoUnitario.comparedTo(0) === 1) {
                                formikRef.current?.setFieldValue('KgNeto', toFixedDecimal(kgNetoUnitario.mul(valor), 2));
                                if (unidadEstadistica === '01') {
                                    formikRef.current?.setFieldValue('CantidadEstadistica', toFixedDecimal(kgNetoUnitario.mul(valor), 2));
                                }
                            }
                        } catch (error) {
                        }
                    }
                    if (deshabilitarPrecioUnitario) {
                        try {
                            let cantidadDeclarada = new Decimal(valor);
                            if (cantidadDeclarada.comparedTo(0) === 1) {
                                let fobTotal = new Decimal(formikRef.current?.values?.TotalFob);
                                formikRef.current?.setFieldValue('PrecioUnitario', toFixedDecimal(fobTotal.div(cantidadDeclarada), 5));
                            } else {
                                formikRef.current?.setFieldValue('PrecioUnitario', '0');
                            }
                        } catch (error) {
                            console.log('Error al calcular precio unitario', error);
                        }
                    } else {
                        try {
                            let precioUnitario = new Decimal(formikRef.current?.values?.PrecioUnitario);
                            formikRef.current?.setFieldValue('TotalFob', toFixedDecimal(precioUnitario.mul(valor), 2));
                        } catch (error) {
                            console.log('Error al calcular total fob', error);
                        }
                    }
                    cambiandoValores.current = false;
                }
            } catch (error) {
                cambiandoValores.current = false;
            }
        }
    }
    function cantidadEstadisticaOnChanged(valor: string) {
        if (formikRef?.current?.values?.CodigoUnidadDeclarada?.toString() === '01' && formikRef.current?.values?.CodigoUnidadEstadistica?.toString() === '01') {
            formikRef?.current?.setFieldValue('CantidadDeclarada', valor)
            formikRef?.current?.setFieldValue('KgNeto', toFixedDecimal(valor, 2))
        }
        if (formikRef.current?.values?.CodigoUnidadEstadistica?.toString() === '01') {
            formikRef?.current?.setFieldValue('KgNeto', toFixedDecimal(valor, 2))
        }
        if (cargando === EstadoCargando.Listo && !cambiandoValores.current) {
            try {
                // eslint-disable-next-line
                let unidadEstadistica = formikRef.current?.values?.CodigoUnidadEstadistica;
                if (isNullOrWhiteSpace(valor)) {
                    cambiandoValores.current = true;
                    // if (unidadEstadistica === '01') {
                    //     formikRef.current?.setFieldValue('KgNeto', '');
                    // }
                    cambiandoValores.current = false;
                } else {
                    cambiandoValores.current = true;
                    try {
                        // eslint-disable-next-line
                        let cantidadEstadistica = new Decimal(valor);
                        // if (unidadEstadistica === '01') {
                        //     formikRef.current?.setFieldValue('KgNeto', toFixedDecimal(cantidadEstadistica, 2));
                        // }
                    } catch (error) {
                    }
                    cambiandoValores.current = false;
                }
            } catch (error) {
                cambiandoValores.current = false;
            }
        }
    }
    function kgNetoOnChanged(valor: string) {
        if (formikRef?.current?.values?.CodigoUnidadDeclarada?.toString() === '01' && formikRef.current?.values?.CodigoUnidadEstadistica?.toString() === '01') {
            formikRef?.current?.setFieldValue('CantidadDeclarada', valor)
            formikRef?.current?.setFieldValue('CantidadEstadistica', valor)
        }
        if (cargando === EstadoCargando.Listo && !cambiandoValores.current) {
            try {
                if (!isNullOrWhiteSpace(valor)) {
                    cambiandoValores.current = true;
                    // eslint-disable-next-line
                    // let unidadEstadistica = formikRef.current?.values?.CodigoUnidadEstadistica;
                    // if (unidadEstadistica === '01') {
                    //     try {
                    //         let kgNeto = new Decimal(valor);
                    //         formikRef.current?.setFieldValue('CantidadEstadistica', toFixedDecimal(kgNeto, 2));
                    //     } catch (error) {
                    //     }
                    // }
                    cambiandoValores.current = false;
                }
            } catch (error) {
                cambiandoValores.current = false;
            }
        }
    }
    function kgNetoUnitarioOnChanged(valor: string) {
        if (cargando === EstadoCargando.Listo && !cambiandoValores.current) {
            try {
                if (!isNullOrWhiteSpace(valor)) {
                    cambiandoValores.current = true;
                    let kgNetoUnitario = new Decimal(valor);
                    if (kgNetoUnitario.comparedTo(0) === 1) {
                        let cantidadDeclarada = new Decimal(formikRef.current?.values?.CantidadDeclarada);
                        formikRef.current?.setFieldValue('KgNeto', toFixedDecimal(kgNetoUnitario.mul(cantidadDeclarada), 2));
                        let unidadEstadistica = formikRef.current?.values?.CodigoUnidadEstadistica;
                        if (unidadEstadistica === '01') {
                            formikRef.current?.setFieldValue('CantidadEstadistica', toFixedDecimal(kgNetoUnitario.mul(cantidadDeclarada), 2));
                        }
                    }
                    cambiandoValores.current = false;
                }
            } catch (error) {
                cambiandoValores.current = false;
            }
        }
    }
    function precioUnitarioOnChanged(valor: string) {
        if (cargando === EstadoCargando.Listo && !cambiandoValores.current) {
            try {
                if (!isNullOrWhiteSpace(valor)) {
                    cambiandoValores.current = true;
                    let precioUnitario = new Decimal(valor);
                    let cantidadDeclarada = new Decimal(formikRef.current?.values?.CantidadDeclarada);
                    formikRef.current?.setFieldValue('TotalFob', toFixedDecimal(precioUnitario.mul(cantidadDeclarada), 2));
                    cambiandoValores.current = false;
                }
            } catch (error) {
                cambiandoValores.current = false;
            }
        }
    }
    function totalFobOnChanged(valor: string) {
        if (cargando === EstadoCargando.Listo && !cambiandoValores.current) {
            try {
                if (!isNullOrWhiteSpace(valor)) {
                    cambiandoValores.current = true;
                    let totalFob = new Decimal(valor);
                    let cantidadDeclarada = new Decimal(formikRef.current?.values?.CantidadDeclarada);
                    if (cantidadDeclarada.comparedTo(0) === 1) {
                        formikRef.current?.setFieldValue('PrecioUnitario', toFixedDecimal(totalFob.div(cantidadDeclarada), 5));
                    } else {
                        formikRef.current?.setFieldValue('PrecioUnitario', '0');
                    }
                    cambiandoValores.current = false;
                }
            } catch (error) {
                cambiandoValores.current = false;
            }
        }
    }
    useEffect(() => {
        async function cargar() {
            try {
                let respuesta = await api.getCaratula(interno);
                let subregimenCaratula = null;
                if (!isNullOrWhiteSpace(respuesta.CodigoSubregimen)) {
                    let subregimenes = await api.getSubregimenes();
                    subregimenCaratula = subregimenes.find((item: any) => item.Codigo === respuesta.CodigoSubregimen);
                }
                updateCaratula(respuesta);
                updateSubregimen(subregimenCaratula);
                // updateOptionsFacturas(respuesta.Facturas?.map((valor: any) => ({ value: valor, label: valor })) ?? []);
                updateOptionsCodigoProrrateo(respuesta.CodigosProrrateo?.map((item: any) => ({ value: item.Codigo, label: item.Descripcion })) ?? []);
                respuesta = await api.getCatalogos();
                let catalogos = respuesta?.map((catalogo: any) => ({ value: catalogo.Codigo, label: catalogo.Descripcion })) ?? [];
                let ponerFocoEnNcm = false;

                // cargar configuracion  0 es total 1 es unitario
                let option = ConfiguracionStorage.loadConfigStorage('SeleccionFobTotalPrecioUnitario') ?? 0;
                updateDeshabilitarPrecioUnitario((parseInt(option, 10) === 0));
                if (id) {
                    subitem.current = await api.getSubitem(id);
                    if (!subitem.current) {
                        // updateNotFound(true);
                        return;
                    }
                    if (!isNullOrWhiteSpace(subitem.current.IdCatalogo) &&
                        !catalogos.map((c: any) => c.value).includes(subitem.current.IdCatalogo)) {
                        catalogos.push({ value: subitem.current.IdCatalogo, label: '' });
                    }
                    setIdArticuloValue(subitem.current.IdArticulo?.trimEnd())
                    formikRef.current?.resetForm({
                        values: {
                            'ForzarSufijos': false,
                            'IdCatalogo': subitem.current.IdCatalogo,
                            'IdArticulo': subitem.current.IdArticulo?.trimEnd(),
                            'Ncm': subitem.current.Ncm,
                            'Descripcion': subitem.current.Descripcion,
                            'DescripcionMaria': subitem.current.DescripcionMaria,
                            'KgNetoUnitario': toFixedDecimal(subitem.current.KgNetoUnitario, 5),
                            'CodigoUnidadDeclarada': subitem.current.CodigoUnidadDeclarada,
                            'CodigoPaisOrigen': subitem.current.CodigoPaisOrigen,
                            'CodigoPaisProcedencia': subitem.current.CodigoPaisProcedencia,
                            'Sufijos': subitem.current.Sufijos,
                            'CodigoEstadoMercaderia': subitem.current.CodigoEstadoMercaderia,
                            'PrecioUnitario': subitem.current.PrecioUnitario,
                            'Otros': subitem.current.Otros,
                            'Naladisa': subitem.current.Naladisa,
                            'CantidadEstadistica': toFixedDecimal(subitem.current.CantidadEstadistica, 2),
                            'AjusteIncluir': toFixedDecimal(subitem.current.AjusteIncluir, 2),
                            'AjusteDeducir': toFixedDecimal(subitem.current.AjusteDeducir, 2),
                            'CantidadDeclarada': toFixedDecimal(subitem.current.CantidadDeclarada, 2),
                            'TotalFob': toFixedDecimal(subitem.current.TotalFob, 2),
                            'KgNeto': toFixedDecimal(subitem.current.KgNeto, 2),
                            'KgBruto': toFixedDecimal(subitem.current.KgBruto, 2),
                            'Filler': subitem.current.Filler,
                            'Lista': subitem.current.Lista,
                            'CodigoEAN': subitem.current.CodigoEAN,
                            'CodigoProrrateo': subitem.current.CodigoProrrateo,
                            'InsumosAConsumo': toFixedDecimal(subitem.current.InsumosAConsumo, 2),
                            'InsumosTemporales': toFixedDecimal(subitem.current.InsumosTemporales, 2),
                            'NroFactura': subitem.current.NroFactura,
                            'CodigoAfip': subitem.current.CodigoAfip,
                        }
                    });
                    await cargarDatosNcm(subitem.current.Ncm);
                    respuesta = await api.getCancelacionesSubitemsCaratula(interno, id);
                    // updateCancelaciones(respuesta ?? []);
                    if (!isNullOrWhiteSpace(subitem.current.IdCatalogo) && !isNullOrWhiteSpace(subitem.current.IdArticulo?.trimEnd())) {
                        try {
                            respuesta = await api.getArticulo(subitem.current.IdCatalogo, subitem.current.IdArticulo?.trimEnd());
                            // updateEstadoIntervenciones({ tipo: 'setIntervencionesArticulo', valor: respuesta?.Intervenciones });
                        } catch (error) {
                            if (api.isCancel(error)) {
                                throw error;
                            } else {
                                console.error('Error al buscar intervenciones articulo', error);
                            }
                        }
                    }
                } else {
                    let configuracionEmpresa = await api.getConfiguracionEmpresa();
                    let nroClienteAlphaGuardado = null, empresaGuardada = null, carpetaGuardada = null, resto: any = null;
                    try {
                        ({
                            NroClienteAlpha: nroClienteAlphaGuardado, EmpresaId: empresaGuardada,
                            Carpeta: carpetaGuardada, ...resto
                        } = JSON.parse(storage.getItem(claveMantenerDatosSubitems) ?? ''));
                    } catch {
                    }
                    if (nroClienteAlphaGuardado === userInfo.nroClienteAlpha
                        && empresaGuardada === userInfo.empresaActual && carpetaGuardada === interno
                        && configuracionEmpresa.Configuracion?.MantenerDatosAlGuardarSubitem) {
                        formikRef.current?.setValues(resto);
                        ponerFocoEnNcm = true;
                    } else {
                        formikRef.current?.setFieldValue('IdCatalogo', storage.getItem(claveUltimoCatalogo) ?? '');
                        formikRef.current?.setFieldValue('IdArticulo', storage.getItem(claveUltimoArticulo) ?? '');
                    }
                }
                updateOptionsCatalogos(catalogos);
                updateCargando(EstadoCargando.Listo);
                if (ponerFocoEnNcm) {
                    selectNcmRef.current?.focus();
                }
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar subitem', error);
                    // mostrarError('Error al cargar subitem');
                }
            }
        }
        cargar();
        //eslint-disable-next-line 
    }, []);
    useEffect(() => {
        async function cargar() {
            try {
                updateCargando(EstadoCargando.Cargando);
                let respuesta = await api.getCatalogos();
                let catalogos = respuesta?.map((catalogo: any) => ({ value: catalogo.Codigo, label: catalogo.Descripcion })) ?? [];
                updateOptionsCatalogos(catalogos);
                if (!isNullOrWhiteSpace(estadoModalCatalogos.valorSeleccionado)) {
                    formikRef.current?.setFieldValue('IdCatalogo', estadoModalCatalogos.valorSeleccionado);
                }
                updateCargando(EstadoCargando.Listo);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar catalogos', error);
                    // mostrarError('Error al cargar catalogos');
                    updateCargando(EstadoCargando.Listo);
                }
            }
        }
        if (!estadoModalCatalogos.abierto && cargando !== EstadoCargando.CargaInicial) {
            cargar();
        }
        //eslint-disable-next-line
    }, [estadoModalCatalogos.abierto]);

    async function getMarca(IdCatalogo: string) {
        updateCargando(EstadoCargando.Cargando);
        try {
            let respuesta = await api.getCatalogo(IdCatalogo);
            updateCargando(EstadoCargando.Listo);
            return respuesta.SufijoAA;
        } catch (error) {
            if (api.isCancel(error)) {
                throw error;
            } else {
                console.error('Error al buscar marca de catalogo', error);
                updateCargando(EstadoCargando.Listo);
                return null;
            }
        }
    }

    async function preValidacion() {
        let values = formikRef.current!.values;
        try {
            let hayCatalogo = !isNullOrWhiteSpace(values.IdCatalogo);
            let hayArticulo = !isNullOrWhiteSpace(values.IdArticulo?.trimEnd());
            if (hayCatalogo && !hayArticulo) {
                formikRef.current!.setFieldError('IdArticulo', 'Debe ingresar el codigo de articulo');
                formikRef.current!.setFieldTouched('IdArticulo', true, false);
                return false;
            } else if (!hayCatalogo && hayArticulo) {
                formikRef.current!.setFieldError('IdCatalogo', 'Debe ingresar el codigo de catalogo');
                formikRef.current!.setFieldTouched('IdCatalogo', true, false);
                return false;
            }
            if (isNullOrWhiteSpace(values.Ncm)) {
                formikRef.current!.setFieldError('Ncm', 'Debe ingresar el ncm');
                formikRef.current!.setFieldTouched('Ncm', true, false);
                return false;
            }
            ncmValidado.current = await api.validarNcmArreglandoPuntos(values.Ncm);
            if (ncmValidado.current) {
                formikRef.current!.setFieldValue('CodigoUnidadEstadistica', ncmValidado.current.Ncm.m012, false);
                await cargarUnidadEstadistica(ncmValidado.current.Ncm.m012);
                updateTieneLna(!!ncmValidado.current.LNA);
                updateTieneEstampilla(ncmValidado.current.Ncm.m042 === '*');
                if (isNullOrWhiteSpace(values.CodigoPaisOrigen)) {
                    updateTieneValorCriterio(false);
                } else {
                    let existe = await existeValorCriterio(ncmValidado.current.Ncm.POSICION_ARANCELARIA, values.CodigoPaisOrigen);
                    updateTieneValorCriterio(existe);
                }
                if (subregimen?.Codigo?.startsWith('TRM')) {
                    if (ncmValidado.current.Ncm.Tipo !== TipoNcm.TRAM) {
                        formikRef.current!.setFieldError('Ncm', 'Debe ingresar un codigo afip de TRAM para un subitem en la caratula actual');
                        formikRef.current!.setFieldTouched('Ncm', true, false);
                        return false;
                    }
                } else if (subregimen?.Codigo?.startsWith('ER')) {
                    if (ncmValidado.current.Ncm.Tipo !== TipoNcm.Rancho) {
                        formikRef.current!.setFieldError('Ncm', 'Debe ingresar un codigo afip de rancho para un subitem en la caratula actual');
                        formikRef.current!.setFieldTouched('Ncm', true, false);
                        return false;
                    }
                } else if (subregimen?.Codigo?.startsWith('PI')) {
                    if (ncmValidado.current.Ncm.Tipo !== TipoNcm.Particular) {
                        formikRef.current!.setFieldError('Ncm', 'Debe ingresar un codigo afip de PARTICULARES para un subitem en la caratula actual');
                        formikRef.current!.setFieldTouched('Ncm', true, false);
                        return false;
                    }
                } else {
                    if (ncmValidado.current.Ncm.Tipo !== TipoNcm.Ncm) {
                        formikRef.current!.setFieldError('Ncm', 'No puede ingresar un codigo afip para un subitem en la caratula actual');
                        formikRef.current!.setFieldTouched('Ncm', true, false);
                        return false;
                    }
                }
            } else {
                updateTieneLna(false);
                updateTieneEstampilla(false);
                formikRef.current!.setFieldError('Ncm', 'El ncm ingresado no es valido');
                formikRef.current!.setFieldTouched('Ncm', true, false);
                return false;
            }
        } catch (error) {
            if (api.isCancel(error)) {
                throw error;
            } else {
                console.error('Error al validar ncm', error);
                // mostrarError('Error al validar ncm');
                return false;
            }
        }
        cambiandoValores.current = true;
        let kgNetoYaSeteado = false;
        if (!isNullOrWhiteSpace(values.CantidadDeclarada)) {
            try {
                let cantidadDeclarada = new Decimal(values.CantidadDeclarada);
                if (cantidadDeclarada.comparedTo(0) === 1) {
                    if (values.CodigoUnidadDeclarada === values.CodigoUnidadEstadistica) {
                        if (isNullOrWhiteSpace(values.CantidadEstadistica)) {
                            formikRef.current!.setFieldValue('CantidadEstadistica', values.CantidadDeclarada, false);
                        } else {
                            try {
                                let cantidadEstadistica = new Decimal(values.CantidadEstadistica);
                                if (cantidadEstadistica.comparedTo(0) !== 1) {
                                    formikRef.current!.setFieldValue('CantidadEstadistica', values.CantidadDeclarada, false);
                                }
                            } catch (error) { }
                        }
                    }
                    if (values.CodigoUnidadDeclarada === '01') {
                        if (isNullOrWhiteSpace(values.KgNeto)) {
                            formikRef.current!.setFieldValue('KgNeto', values.CantidadDeclarada, false);
                            kgNetoYaSeteado = true;
                        } else {
                            try {
                                let kgNeto = new Decimal(values.KgNeto);
                                if (kgNeto.comparedTo(0) !== 1) {
                                    formikRef.current!.setFieldValue('KgNeto', values.CantidadDeclarada, false);
                                    kgNetoYaSeteado = true;
                                }
                            } catch (error) { }
                        }
                    }
                }
            } catch (error) { }
        }
        if (!isNullOrWhiteSpace(values.KgNeto)) {
            try {
                let kgNeto = new Decimal(values.KgNeto);
                if (kgNeto.comparedTo(0) === 1) {
                    if (values.CodigoUnidadEstadistica === '01') {
                        if (isNullOrWhiteSpace(values.CantidadEstadistica)) {
                            formikRef.current!.setFieldValue('CantidadEstadistica', values.KgNeto);
                        } else {
                            try {
                                let cantidadEstadistica = new Decimal(values.CantidadEstadistica);
                                if (cantidadEstadistica.comparedTo(0) !== 1) {
                                    formikRef.current!.setFieldValue('CantidadEstadistica', values.KgNeto);
                                }
                            } catch (error) { }
                        }
                    }
                    if (!kgNetoYaSeteado && values.CodigoUnidadDeclarada === '01') {
                        if (isNullOrWhiteSpace(values.CantidadDeclarada)) {
                            formikRef.current!.setFieldValue('CantidadDeclarada', values.KgNeto);
                        } else {
                            try {
                                let cantidadDeclarada = new Decimal(values.CantidadDeclarada);
                                if (cantidadDeclarada.comparedTo(0) !== 1) {
                                    formikRef.current!.setFieldValue('CantidadDeclarada', values.KgNeto);
                                }
                            } catch (error) { }
                        }
                    }
                }
            } catch (error) { }
        }
        cambiandoValores.current = false;
        return true;
    }

    async function submit(values: any, helpers: FormikHelpers<any>) {
        setMostrarErrores(true)
        updateCargando(EstadoCargando.Cargando)
        let { ForzarSufijos, ...values2 } = values;
        if (!isNullOrWhiteSpace(values2.TotalFob) && !isNullOrWhiteSpace(values2.CantidadDeclarada)
            && !isNullOrWhiteSpace(values2.PrecioUnitario)) {
            //validar cantidad/total=unitario para evitar problemas con decimales
            const precioUnitarioCalculado = new Decimal(values2.TotalFob).toDecimalPlaces(2)
                .div(new Decimal(values2.CantidadDeclarada).toDecimalPlaces(2));
            if (precioUnitarioCalculado.toDecimalPlaces(5).comparedTo(new Decimal(values2.PrecioUnitario).toDecimalPlaces(5)) !== 0) {
                // mostrarError('El total FOB debe ser igual a la cantidad declarada por el precio unitario');
                return;
            }
        }
        let marca = null;
        if (!isNullOrWhiteSpace(values2.IdCatalogo)) {
            try {
                let respuesta = await api.getCatalogo(values2.IdCatalogo);
                if (respuesta) {
                    marca = respuesta.SufijoAA;
                } else {
                    helpers.setFieldError('IdCatalogo', 'El catalogo no existe');
                    updateCargando(EstadoCargando.Listo)
                    return;
                }
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al validar catalogo', error);
                    updateCargando(EstadoCargando.Listo)
                    // mostrarError('Error al validar catalogo');
                }
                return;
            }
        }
        return sufijosRef.current?.mostrar(!ForzarSufijos, ncmValidado.current.Ncm.POSICION_ARANCELARIA, values2.Sufijos, marca, values2.IdArticulo?.trimEnd())
            .then(async sufijosFinal => {
                handleClose()
                let subitemNuevo = {
                    ...values2,
                    NroClienteAlpha: userInfo.nroClienteAlpha,
                    EmpresaId: userInfo.empresaActual,
                    Carpeta: interno,
                    Ncm: ncmValidado.current.Ncm.POSICION_ARANCELARIA,
                    Sufijos: sufijosFinal,
                    KgNetoUnitario: enCeroSiEsVacio(toFixedDecimal(values2.KgNetoUnitario, 5)),
                    CantidadEstadistica: enCeroSiEsVacio(toFixedDecimal(values2.CantidadEstadistica, 2)),
                    AjusteIncluir: toFixedDecimal(values2.AjusteIncluir, 2),
                    AjusteDeducir: toFixedDecimal(values2.AjusteDeducir, 2),
                    CantidadDeclarada: enCeroSiEsVacio(toFixedDecimal(values2.CantidadDeclarada, 2)),
                    PrecioUnitario: enCeroSiEsVacio(toFixedDecimal(values2.PrecioUnitario, 5)),
                    TotalFob: enCeroSiEsVacio(toFixedDecimal(values2.TotalFob, 2)),
                    KgNeto: enCeroSiEsVacio(toFixedDecimal(values2.KgNeto, 2)),
                    KgBruto: toFixedDecimal(values2.KgBruto, 2),
                    InsumosAConsumo: toFixedDecimal(values2.InsumosAConsumo, 2),
                    InsumosTemporales: toFixedDecimal(values2.InsumosTemporales, 2),
                    ModificadoPor: userInfo.claims.nameid,
                    ModificadoEl: DateTime.local().toISO(),
                };
                if (subitem.current) {
                    subitemNuevo.Id = subitem.current.Id;
                    subitemNuevo.CreadoPor = subitem.current.CreadoPor;
                    subitemNuevo.CreadoEl = subitem.current.CreadoEl;
                    subitemNuevo.Orden = subitem.current.Orden;
                    subitemNuevo.TipoItem = subitem.current.TipoItem;
                } else {
                    subitemNuevo.CreadoPor = userInfo.claims.nameid;
                    subitemNuevo.CreadoEl = DateTime.local().toISO();
                    subitemNuevo.TipoItem = 'N';
                }
                try {
                    let puedeGuardar = await api.obtenerLock(TipoLock.Caratula, interno);
                    if (puedeGuardar) {
                        let { exito, error } = await api.insertSubitem(subitemNuevo);
                        if (exito) {
                            await api.eliminarLock(TipoLock.Caratula, interno);
                            storage.setItem(claveUltimoCatalogo, subitemNuevo.IdCatalogo);
                            storage.setItem(claveUltimoArticulo, subitemNuevo.IdArticulo?.trimEnd());
                            setMostrarErrores(false)
                            setIdArticuloValue('')
                            handleClose()
                            updateCargando(EstadoCargando.Listo)
                            let { Id, CreadoPor, CreadoEl, Orden, TipoItem, ModificadoPor, ModificadoEl,
                                ...objMantenerDatos } = subitemNuevo;
                            storage.setItem(claveMantenerDatosSubitems, JSON.stringify({
                                ...objMantenerDatos,
                                CantidadDeclarada: undefined,
                                PrecioUnitario: undefined,
                                TotalFob: undefined
                            }));
                            // if (!continuarSufijosFormEnter) {
                            //     history.replace("/caratulas/" + encodeURIComponent(interno) + "/subitems");
                            //     handleClose()
                            // }
                        } else {
                            updateMensajeErrorAlert(error);
                        }
                    } else {
                        updateCargando(EstadoCargando.Listo)
                        updateMensajeErrorAlert('No se pudo guardar el subitem porque otra persona está usando la caratula');
                    }
                } catch (error) {
                    if (!api.isCancel(error)) {
                        updateCargando(EstadoCargando.Listo)
                        console.error('Error al guardar subitem', error);
                        // mostrarError('Error al guardar subitem');
                    }
                }
            }).catch(() => {
                updateCargando(EstadoCargando.Listo)
            }).finally(() => {
                updateCargando(EstadoCargando.Listo)
            })
    }

    async function onClickBotonSubmit(forzarSufijos: boolean) {
        //poner ultimo parametro en false para que no borre errores cuando se actualice el valor
        updateCargando(EstadoCargando.Cargando)
        formikRef.current?.setFieldValue('ForzarSufijos', forzarSufijos, false);
        updateCargando(EstadoCargando.Cargando);
        try {
            const preValidacionOK = await preValidacion();
            updateCargando(EstadoCargando.Listo);
            if (preValidacionOK) {
                formikRef.current?.submitForm();
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al validar subitem', error);
                mostrarError('Error al validar subitem');
                updateCargando(EstadoCargando.Listo);
            }
        }
    }
    function onClickBusquedaArticulos() {
        handleClose()
        busquedaArticulosRef.current!.mostrar(formikRef.current?.values?.IdCatalogo).then(resultado => {
            formikRef.current?.setFieldValue('IdCatalogo', resultado.codigoCatalogo);
            formikRef.current?.setFieldValue('IdArticulo', resultado.codigoArticulo?.trimEnd());
            cargarDatosArticulo(resultado.codigoCatalogo, resultado.codigoArticulo);
        }).catch(() => { });
    }
    const cancelarButtonRef = useRef<HTMLButtonElement>(null)
    const guardarButtonRef = useRef<HTMLButtonElement>(null)

    const manejarTeclaPresionada = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'F9') {
            setMostrarErrores(true)
            onClickBotonSubmit(false)
        }
        if (e.ctrlKey && e.key === 'F9') {
            setMostrarErrores(true)
            // setShowModalCargaSimple(false)
            onClickBotonSubmit(true)
        }
        if (e.ctrlKey && e.key.toLowerCase() === "b") {
            e.preventDefault();
            e.stopPropagation();
            onClickBusquedaArticulos();
        }
    };

    const tabs = ["tab1", "tab2", "tab3"];

    const handleNextTab = useCallback(() => {
        setKey((prevKey) => {
            const currentIndex = tabs.indexOf(prevKey);
            if (currentIndex === 0) {
                setTimeout(() => {
                    selectNcmRef.current?.focus();
                }, 500)
            }
            if (currentIndex === 1) {
                setTimeout(() => {
                    fillerRef.current?.focus();
                }, 500)
            }
            return currentIndex < tabs.length - 1 ? tabs[currentIndex + 1] : prevKey;
        });
        // eslint-disable-next-line 
    }, []);

    const updateDataCatalogo = (catalogo: any) => {
        setShowModalCargaSimple(true)
        setCatalogoSelect(catalogo)
    }
    const handlePreviousTab = useCallback(() => {
        setKey((prevKey) => {
            const currentIndex = tabs.indexOf(prevKey);
            if (currentIndex === 2) {
                setTimeout(() => {
                    selectNcmRef.current?.focus();
                }, 500)
            }
            if (currentIndex === 1) {
                setTimeout(() => {
                    descripcionRef.current?.focus();
                }, 500)
            }
            return currentIndex > 0 ? tabs[currentIndex - 1] : prevKey;
        });
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "PageDown") {
                handleNextTab();
            } else if (event.key === "PageUp") {
                handlePreviousTab();
            } else if (event.key === 'Escape') {
                handleClose()
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handlePreviousTab, handleNextTab]);

    useEffect(() => {
        if (!show) {
            formikRef.current?.resetForm()
            setErroresValidacion('')
            setIdArticuloValue('')
            setKey("tab1")
        }
        if (show) {
            if (storage.getItem(claveUltimoCatalogo)) {
                formikRef.current?.setFieldValue('IdCatalogo', storage.getItem(claveUltimoCatalogo))
                IdArticuloRef.current?.focus()
            }
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    return (
        <>
            <BlockUi blocking={cargando !== EstadoCargando.Listo}   >
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>{id ? 'Modificar Subitem' : 'Crear Subitem'} de <Link to={'/caratulas/' + encodeURIComponent(interno)}>{interno}</Link></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div onKeyDown={manejarTeclaPresionada}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', padding: 5 }}>
                                {mensajeArticuloNoEncontrado?.length > 0 &&
                                    <Alert variant="danger" style={{ marginLeft: 10 }}>
                                        <Alert.Heading> No se encontró un articulo con el código ingresado</Alert.Heading>
                                    </Alert>
                                }
                            </div>
                            {mensajeErrorAlert && (<Alert variant="danger">{mensajeErrorAlert}</Alert>)}
                            <Formik initialValues={{
                                Ncm: '', KgNetoUnitario: 0, ForzarSufijos: false, KgBruto: 0,
                                InsumosAConsumo: 0, InsumosTemporales: 0
                            }} innerRef={formikRef} validationSchema={schema}
                            validate={(values) => {
                                try {
                                  schema.validateSync(values, { abortEarly: false });
                                  setErroresValidacion('');
                                  return {};
                                } catch (error) {
                                  console.log('error', error);
                                  if (error instanceof Yup.ValidationError) {
                                    if (mostrarErrores) {
                                      setErroresValidacion(
                                        `Errores de validación:\n${error.inner.map(e => `- ${e.message}`).join('\n')}`
                                      );
                                    return error.inner.reduce((acc: Record<string, string>, err) => {
                                        acc[err.path] = err.message;
                                        return acc;
                                    }, {});
                                    }
                                  }
                                }
                              }}
                                onSubmit={submit}>
                                <BlockUi blocking={cargando !== EstadoCargando.Listo} >
                                    <MyForm blocking={cargando !== EstadoCargando.Listo}>
                                        <Tabs activeKey={key} onSelect={(k) => setKey(k || "tab1")} className="mb-3">
                                            <Tab eventKey="tab1" title="Catalogo" style={{ padding: '2%' }}>
                                                <Form.Row >
                                                    <Form.Group as={Col} lg={6}>
                                                        <MySelectConOptionABM
                                                            name="IdCatalogo"
                                                            label="Catalogo"
                                                            options={optionsCatalogos}
                                                            isDisabled={caratula.Bloqueada}
                                                            getOptionLabel={optionLabelConCodigo}
                                                            labelOptionABM="Nuevo catalogo..."
                                                            onSelectABM={() => {
                                                                handleClose()
                                                                updateEstadoModalCatalogos({ tipo: 'mostrar' })
                                                            }}
                                                            onValueChange={option => {
                                                                if ((formikRef.current?.values?.IdCatalogo && option) === formikRef?.current?.initialValues?.IdCatalogo) {
                                                                    return
                                                                } else {
                                                                    // updateEstadoIntervenciones({ tipo: 'setIntervencionesArticulo', valor: [] });
                                                                    formikRef.current!.setFieldValue('IdArticulo', '');
                                                                }
                                                            }}></MySelectConOptionABM>
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm={12} lg={6}>
                                                        <Form.Label>Código Articulo</Form.Label>
                                                        <Form.Control type="text" autoFocus ref={IdArticuloRef} name="IdArticulo" onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                            if (e.key === 'Enter') {
                                                                setMensajeArticuloNoEncontrado('')
                                                                let IdArticulo = IdArticuloValue?.trimEnd()
                                                                let IdCatalogo = formikRef.current!.values.IdCatalogo;
                                                                if (!isNullOrWhiteSpace(IdCatalogo) && !isNullOrWhiteSpace(IdArticulo)) {
                                                                    cargarDatosArticulo(IdCatalogo, IdArticulo);
                                                                } else {
                                                                    // updateEstadoIntervenciones({ tipo: 'setIntervencionesArticulo', valor: [] });
                                                                }
                                                            }
                                                        }} onChange={(e) => {
                                                            formikRef.current?.setFieldValue('IdArticulo', e?.target?.value)
                                                            setIdArticuloValue(e?.target?.value)
                                                        }
                                                        } placeholder="Codigo Articulo" value={IdArticuloValue} disabled={caratula.Bloqueada} />
                                                    </Form.Group>
                                                    <Form.Group style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'row' }} as={Col} lg={12} >
                                                        <Button onClick={e => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setCatalogoSelect(null);
                                                            onClickBusquedaArticulos();
                                                        }} disabled={caratula.Bloqueada}>Abrir búsqueda artículos [CTRL + B]</Button>

                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col}>
                                                        <MyTextarea name="Descripcion" label="Descripción Catalogo" disabled={caratula.Bloqueada} ref={descripcionRef} onKeyDown={(e: any) => {
                                                            if (e.keyCode === 13 && e.shiftKey) {
                                                                if (caratula.Bloqueada) {
                                                                    kgNetoUnitarioRef?.current?.focus();
                                                                } else {
                                                                    descripcionSediRef?.current?.focus();
                                                                }
                                                            } else if (e.keyCode === 13 && e.ctrlKey) {
                                                                codigoAfipRef?.current?.focus();
                                                            }
                                                        }}></MyTextarea>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Group as={Col}>

                                                    <Form.Row>
                                                        <Form.Group as={Col} lg={12}>
                                                            <MyTextarea name="DescripcionMaria" label="Descripción SEDI" disabled={caratula.Bloqueada || (subregimen?.Codigo !== 'SIMI' && subregimen?.Codigo !== 'SIRA' && subregimen?.Codigo !== 'SEDI')} ref={descripcionSediRef} onKeyDown={(e: any) => {
                                                                if (e.keyCode === 13 && e.shiftKey) {
                                                                    kgNetoUnitarioRef?.current?.focus()
                                                                }
                                                            }}></MyTextarea>
                                                        </Form.Group>

                                                    </Form.Row>
                                                </Form.Group>
                                                <Form.Row>
                                                    <Form.Group as={Col} lg={6}>
                                                        <MyAsyncSelect
                                                            name="CodigoAfip"
                                                            label="Codigo Afip (para particulares)"
                                                            ref={codigoAfipRef}
                                                            loadOptions={api.cargarNcm([TipoNcm.Particular])}
                                                            isDisabled={caratula.Bloqueada}></MyAsyncSelect>
                                                    </Form.Group>
                                                    <Form.Group as={Col} lg={6}>
                                                        <MyFormControl disabled={caratula.Bloqueada} type="number" name="KgNetoUnitario" ref={kgNetoUnitarioRef} label="Kg Neto Unitario" onKeyDown={(e: any) => {
                                                            if (e.key === 'Enter') {
                                                                handleNextTab()
                                                                setTimeout(() => {
                                                                    selectNcmRef?.current?.focus()
                                                                }, 500);
                                                            }
                                                        }}
                                                            onValueChange={kgNetoUnitarioOnChanged}></MyFormControl>
                                                    </Form.Group>
                                                </Form.Row>
                                            </Tab>
                                            <Tab eventKey="tab2" title="Detalles" style={{ padding: '2%' }}>
                                                <Form.Row>
                                                    <Form.Group as={Col} lg={6}>
                                                        <MyAsyncSelect name="Ncm" defaultOptions={[{ value: formikRef.current?.values?.Ncm, label: formikRef.current?.values?.Ncm }]} label="Posición" ref={selectNcmRef}
                                                            loadOptions={api.cargarNcm()} onValueChange={(option: OnValueChangeArgument) => {
                                                                if (option) {
                                                                    let ncm = option as string;
                                                                    cargarDatosNcm(ncm);
                                                                    if (subregimen?.Codigo?.startsWith('PI')) {
                                                                        formikRef.current!.setFieldValue('CodigoAfip', ncm, false);
                                                                    }
                                                                } else {
                                                                    formikRef.current?.setFieldValue('CodigoUnidadEstadistica', '');
                                                                    updateLabelUnidadEstadistica('');
                                                                }
                                                            }} isDisabled={caratula.Bloqueada}></MyAsyncSelect>
                                                        <p className="mt-2">
                                                            {tieneLna && (<Badge className="p-2 mr-2 mb-2" variant="warning">Tiene LNA</Badge>)}
                                                            {tieneValorCriterio && (<Badge className="p-2 mr-2 mb-2" variant="warning">Valor Criterio</Badge>)}
                                                            {tieneEstampilla && (<Badge className="p-2 mr-2 mb-2" variant="warning">Tiene Estampilla</Badge>)}
                                                        </p>
                                                    </Form.Group>

                                                    <Form.Group as={Col} lg={6}>
                                                        <MySelect name="CodigoUnidadDeclarada" ref={codigoUnidadDeclaradaRef} onValueChange={(e) => {
                                                            formikRef?.current?.setFieldValue('CodigoUnidadDeclarada', e)
                                                            if (e === formikRef.current?.values?.CodigoUnidadEstadistica) {
                                                                formikRef?.current?.setFieldValue('CantidadDeclarada', '')
                                                                formikRef?.current?.setFieldValue('CantidadEstadistica', '')
                                                            }
                                                        }} label="Unidad Declarada" options={() => api.getUnidades().then(convertirDatosGenericosAOption)}
                                                            getOptionLabel={optionLabelConCodigo} isDisabled={caratula.Bloqueada} />
                                                    </Form.Group>
                                                    <Form.Group as={Col} lg={4}>
                                                        <MyFormControl type="number" ref={cantidadDeclaradaRef} onBlur={(e) => {
                                                            formikRef?.current?.setFieldValue('CantidadDeclarada', toFixedDecimal(e?.target?.value, 2))
                                                        }} name="CantidadDeclarada" label="Cantidad Declarada"
                                                            onValueChange={cantidadDeclaradaOnChanged} disabled={caratula.Bloqueada}></MyFormControl>
                                                    </Form.Group>
                                                    <Form.Group as={Col} lg={4}>
                                                        <StyledFormCheck type="radio" id="calcularTotalFob" label="Precio Unitario"
                                                            name="CalcularPrecioUnitario" aria-label="Calcular Total" tabIndex={-1}
                                                            checked={!deshabilitarPrecioUnitario} inline className="custom-check-right" custom
                                                            onChange={(e: any) => updateDeshabilitarPrecioUnitario(!e.target.checked)}>
                                                        </StyledFormCheck>
                                                        <MyFormControl type="number" name="PrecioUnitario" label="Precio Unitario" hideLabel
                                                            onValueChange={precioUnitarioOnChanged} disabled={caratula.Bloqueada}
                                                            readOnly={deshabilitarPrecioUnitario}
                                                            tabIndex={deshabilitarPrecioUnitario ? -1 : undefined}></MyFormControl>
                                                    </Form.Group>
                                                    <Form.Group as={Col} lg={4}>
                                                        <StyledFormCheck type="radio" id="calcularPrecioUnitario"
                                                            label="Total" aria-label="Calcular Precio Unitario" tabIndex={-1}
                                                            checked={deshabilitarPrecioUnitario} inline className="custom-check-right" custom
                                                            onChange={(e: any) => updateDeshabilitarPrecioUnitario(e.target.checked)}>
                                                        </StyledFormCheck>
                                                        <MyFormControl type="number" name="TotalFob" label="Total" hideLabel
                                                            onValueChange={totalFobOnChanged} disabled={caratula.Bloqueada}
                                                            readOnly={!deshabilitarPrecioUnitario}
                                                            tabIndex={!deshabilitarPrecioUnitario ? -1 : undefined}></MyFormControl>
                                                    </Form.Group>
                                                    <Form.Group as={Col} lg={4}>
                                                        <Field type="hidden" name="CodigoUnidadEstadistica"></Field>
                                                        <Form.Label htmlFor="txtUnidadEstadistica">Unidad Estadistica</Form.Label>
                                                        <Form.Control id="txtUnidadEstadistica" type="text" disabled value={labelUnidadEstadistica}></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group as={Col} lg={4}>
                                                        <MyFormControl onBlur={(e) => {
                                                            formikRef?.current?.setFieldValue('CantidadEstadistica', toFixedDecimal(e?.target?.value, 2))
                                                        }} type="number" name="CantidadEstadistica" label="Cantidad Estadistica"
                                                            onValueChange={cantidadEstadisticaOnChanged} disabled={caratula.Bloqueada}></MyFormControl>
                                                    </Form.Group>
                                                    <Form.Group as={Col} lg={4}>
                                                        <MyFormControl type="number" onKeyDown={(e: any) => {
                                                            if (e.key === 'Enter') {
                                                                handleNextTab()
                                                                setTimeout(() => {
                                                                    fillerRef?.current?.focus()
                                                                }, 500);
                                                            }
                                                        }} onBlur={(e) => {
                                                            formikRef?.current?.setFieldValue('KgNeto', toFixedDecimal(e?.target?.value, 2))
                                                        }} name="KgNeto" label="Kg Neto Total"
                                                            onValueChange={kgNetoOnChanged} disabled={caratula.Bloqueada}></MyFormControl>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} lg={12}>
                                                        <MyTextarea name="Sufijos" label="Sufijos" readOnly ref={sufijosRefNew}
                                                            onKeyDown={async (e: any) => {
                                                                if (!caratula.Bloqueada && e.key === 'F8') {
                                                                    try {
                                                                        let marca = null;
                                                                        let IdCatalogo = formikRef.current?.values?.IdCatalogo;
                                                                        if (!isNullOrWhiteSpace(IdCatalogo)) {
                                                                            marca = await getMarca(IdCatalogo);
                                                                        }
                                                                        sufijosRef.current?.mostrar(false, formikRef.current?.values?.Ncm,
                                                                            formikRef.current?.values?.Sufijos, marca,
                                                                            formikRef.current?.values?.IdArticulo?.trimEnd())?.then(ingreso => {
                                                                                formikRef.current?.setFieldValue('Sufijos', ingreso);
                                                                            }).catch(() => { });
                                                                    } catch (error) {
                                                                        if (!api.isCancel(error)) {
                                                                            console.error('Error al cargar sufijos', error);
                                                                            mostrarError('Error al cargar sufijos');
                                                                        }
                                                                    }
                                                                }
                                                                if (e.key === 'Enter' && e.target.value?.length > 0) {
                                                                    // setContinuarSufijosFormEnter(false)
                                                                } else {
                                                                    // setContinuarSufijosFormEnter(true)
                                                                    try {
                                                                        let marca = null;
                                                                        let IdCatalogo = formikRef.current?.values?.IdCatalogo;
                                                                        if (!isNullOrWhiteSpace(IdCatalogo)) {
                                                                            marca = await getMarca(IdCatalogo);
                                                                        }
                                                                        sufijosRef.current?.mostrar(false, formikRef.current?.values?.Ncm,
                                                                            formikRef.current?.values?.Sufijos, marca,
                                                                            formikRef.current?.values?.IdArticulo?.trimEnd())?.then(ingreso => {
                                                                                formikRef.current?.setFieldValue('Sufijos', ingreso);
                                                                                sufijosRefNew?.current?.focus()

                                                                            }).catch(() => { });
                                                                    } catch (error) {
                                                                        if (!api.isCancel(error)) {
                                                                            console.error('Error al cargar sufijos', error);
                                                                            mostrarError('Error al cargar sufijos');
                                                                        }
                                                                    }
                                                                }
                                                            }}></MyTextarea>
                                                    </Form.Group>

                                                </Form.Row>
                                            </Tab>
                                            <Tab eventKey="tab3" title="Información Adicional" style={{ padding: '2%' }}>
                                                <Form.Row>
                                                    <Form.Group as={Col} lg={6}>
                                                        <MyFormControl name="Filler" ref={fillerRef} type="text" label="Filler" disabled={caratula.Bloqueada}></MyFormControl>
                                                    </Form.Group>
                                                    <Form.Group as={Col} lg={6}>
                                                        <MySelect name="CodigoEstadoMercaderia" label="Estado Mercaderia" options={() => api.getEstadosMercaderia().then(convertirDatosGenericosAOption)}
                                                            getOptionLabel={optionLabelConCodigo} isDisabled={caratula.Bloqueada} />

                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} lg={6}>
                                                        <MySelect name="CodigoPaisProcedencia" label="Pais Procedencia/Destino" options={() => api.getPaises().then(convertirDatosGenericosAOption)}
                                                            getOptionLabel={optionLabelConCodigo} isDisabled={caratula.Bloqueada} />
                                                    </Form.Group>
                                                    <Form.Group as={Col} lg={6}>
                                                        <MySelect name="CodigoPaisOrigen" label="Pais Origen/Provincia" options={() => api.getPaises().then(convertirDatosGenericosAOption)}
                                                            getOptionLabel={optionLabelConCodigo} onValueChange={valor => {
                                                                let option = valor as string;
                                                                if (option) {
                                                                    codigoPaisOrigenOnChanged(option);
                                                                }
                                                            }} isDisabled={caratula.Bloqueada} />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} lg={6}>
                                                        <MySelect name="CodigoProrrateo" label="Prorrateo" options={optionsCodigoProrrateo} isDisabled={caratula.Bloqueada} getOptionLabel={optionLabelConCodigo}></MySelect>
                                                    </Form.Group>
                                                    <Form.Group as={Col} lg={6}>
                                                        <MySelect name="Lista" label="Lista" onOptionChange={(e: any) => {
                                                            formikRef.current?.setFieldValue('Lista', e?.value)
                                                            guardarButtonRef?.current?.focus()
                                                        }} options={() => api.getLista().then(convertirDatosGenericosAOption)}
                                                            getOptionLabel={optionLabelConCodigo} isDisabled={caratula.Bloqueada} />
                                                    </Form.Group>
                                                </Form.Row>
                                            </Tab>
                                        </Tabs>
                                        {/* <div>
                                        <Form.Row>
                                           
                                        
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} lg={4}>
                                                <MySelect name="NroFactura" label="Factura" options={optionsFacturas} isDisabled={caratula.Bloqueada}></MySelect>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                           
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} lg={4}>
                                                <MyFormControl name="CodigoEAN" type="text" label="DJCP" ref={DJCPRef} disabled={caratula.Bloqueada}></MyFormControl>
                                            </Form.Group>
                                            <Form.Group as={Col} lg={4}>
                                                <MyFormControl name="Otros" label="Otros" type="text" disabled={caratula.Bloqueada}></MyFormControl>
                                            </Form.Group>
                                            <Form.Group as={Col} lg={4}>
                                                <MyFormControl type="number" name="AjusteIncluir" label="Ajuste Incluir" disabled={caratula.Bloqueada}></MyFormControl>
                                            </Form.Group>
                                            <Form.Group as={Col} lg={4}>
                                                <MyFormControl type="number" name="AjusteDeducir" label="Ajuste Deducir" disabled={caratula.Bloqueada}></MyFormControl>
                                            </Form.Group>
                                            <Form.Group as={Col} lg={4}>
                                                <MyFormControl name="InsumosAConsumo" type="number" label="Insumos A Consumo" disabled={caratula.Bloqueada}></MyFormControl>
                                            </Form.Group>
                                            <Form.Group as={Col} lg={4}>
                                                <MyFormControl name="InsumosTemporales" type="number" label="Insumos Temporales" disabled={caratula.Bloqueada}></MyFormControl>
                                            </Form.Group>
                                            <Form.Group as={Col} lg={4}>
                                                <MyFormControl disabled={caratula.Bloqueada} name="Naladisa" label="Naladisa" type="text" ></MyFormControl>
                                            </Form.Group>
                                        </Form.Row>


                                        <Form.Row>
                                            <Form.Group as={Col} lg={8} controlId="listboxIntervenciones">
                                                <Form.Label>Intervenciones</Form.Label>
                                                <Form.Control as="select" custom multiple disabled style={{ height: 'calc(100% - 2.7rem)' }}>
                                                    {estadoIntervenciones.intervenciones.map((int: any) => <option key={int.Indice}>
                                                        {isNullOrWhiteSpace(int.Id) ? int.Descripcion : `*${int.Descripcion}*`}
                                                    </option>)}
                                                </Form.Control>
                                            </Form.Group>
                                        </Form.Row>


                                    </div> */}
                                        {erroresValidacion?.length > 0 && <p style={{ fontSize: 15, fontWeight: 'bold', color: 'red' }}>{erroresValidacion}</p>}
                                        <Container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                            <Button variant="primary" onClick={handlePreviousTab} disabled={key === "tab1"} style={{ marginRight: 10 }} >
                                                Anterior [RePág]
                                            </Button>
                                            <Button variant="primary" onClick={handleNextTab} disabled={key === "tab3"} >
                                                Siguiente [AvPág]
                                            </Button>
                                        </Container>

                                        <Container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                            <Button variant="danger" className="mt-2 mb-2" onClick={() => {
                                                formikRef.current?.setFieldValue('IdArticulo', '')
                                                formikRef.current?.resetForm()
                                                setMensajeArticuloNoEncontrado('')
                                                setMostrarErrores(false)
                                                handleClose()
                                            }} ref={cancelarButtonRef}>Cancelar [ESC]</Button>
                                            <Button disabled={caratula.Bloqueada} onClick={() => {
                                                setMostrarErrores(true)
                                                let valueTrim = formikRef.current?.values.IdArticulo?.trimEnd()
                                                formikRef.current?.setFieldValue('IdArticulo', valueTrim)
                                                onClickBotonSubmit(false)
                                            }} className="mt-2 ml-2 mb-2" ref={guardarButtonRef}>Guardar [F9]</Button>
                                            <Button style={{ marginLeft: 10 }} disabled={caratula.Bloqueada} onClick={() => {
                                                setMostrarErrores(true)
                                                let valueTrim = formikRef.current?.values.IdArticulo?.trimEnd()
                                                formikRef.current?.setFieldValue('IdArticulo', valueTrim)
                                                onClickBotonSubmit(true)
                                            }} className="mt-2 mb-2">Guardar con sufijos nuevos [CTRL + F9]</Button>
                                        </Container>

                                    </MyForm>
                                </BlockUi>
                            </Formik>
                        </div>

                    </Modal.Body>
                </Modal>
            </BlockUi>
            <MyModal style={{ backgroundColor: 'rgba(0,0,0,0.6)', zIndex: 10000 }} show={estadoModalCatalogos.abierto} onHide={() => updateEstadoModalCatalogos({ tipo: 'cerrar' })}>
                <Modal.Dialog size="xl">
                    <Modal.Body>
                        <InnerRouter initialRoute="/catalogos">
                            <Switch>
                                <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb', 'Operador']} path="/catalogos/add" component={CrearEditarCatalogo}></ProtectedRoute>
                                <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/catalogos/edit/:codigo" component={CrearEditarCatalogo}></ProtectedRoute>
                                <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} exact path="/catalogos">
                                    <CatalogosSimple eventoSeleccionarCatalogo={(item: any) => {
                                        updateEstadoModalCatalogos({ tipo: 'cerrar', valor: item.Codigo })
                                        setShowModalCargaSimple(true)
                                    }}></CatalogosSimple>
                                </ProtectedRoute>
                            </Switch>
                        </InnerRouter>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => {
                            setMensajeArticuloNoEncontrado('')
                            updateEstadoModalCatalogos({ tipo: 'cerrar' })}}>Cerrar</Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </MyModal>
            <IngresoSufijos ref={sufijosRef}></IngresoSufijos>
            <BusquedaArticulosSimple ref={busquedaArticulosRef} codigoArticulo={IdArticuloValue} setCatalogoSelect={updateDataCatalogo} />
        </>


    );
};

export default ModalCargaSimpleSubItem;
