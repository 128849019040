import { useAdvAPI } from 'Paginas/advanced/AdvancedApi'
import React, { useEffect, useState } from 'react'
import BlockUi from 'react-block-ui'
import { Button, Form, Modal } from 'react-bootstrap'

interface propsModalPDFMaria {
    showModalPDFMaria: boolean;
    cargando: boolean;
    updateCargando: ((arg: boolean) => void)
    setShowModalPDFMaria: ((arg: boolean) => void)
    despachoID: Number
    estadoDespacho: string
    nroDespacho: string | null
    nroProvisorio: string | number | null
}

const ModalPDFMaria = ({ showModalPDFMaria, cargando, updateCargando, setShowModalPDFMaria, despachoID, estadoDespacho, nroDespacho, nroProvisorio }: propsModalPDFMaria) => {

    let api = useAdvAPI();

    const [error, setError] = useState('')
    const [urlPDF, setUrlPDF] = useState('')

    const descargarPDFMaria = async () => {
        try {
            updateCargando(true)
            let response = await api.DescargarPDFMaria(despachoID!)
            const base64String = response?.ArchivoBase64;
            if (response?.ArchivoBase64 === null) {
                updateCargando(false);
                setError('No existe el .PDF actual')
            } else {
                const byteCharacters = atob(base64String);
                const byteArrays = [];
                for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                    const slice = byteCharacters.slice(offset, offset + 512);
                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }
                // Crear un Blob a partir del array de bytes
                const blob = new Blob(byteArrays, { type: 'application/octet-stream' });
                // Crear un enlace en memoria y descargar el archivo
                const url = window.URL.createObjectURL(blob);
                setUrlPDF(url)
                updateCargando(false);
            }


        } catch (error) {
            updateCargando(false);
            setShowModalPDFMaria(false)
            console.log('error')
        }
    }

    const DescargarPDFOficializado = async () => {
        try {
            updateCargando(true);
            let response = await api.DescargarPDFOficializado(nroDespacho);
            const blob = new Blob([response], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            setUrlPDF(url);
            updateCargando(false);
        } catch (error) {
            updateCargando(false);
            setError("Error al generar o no se encontró el PDF.");
            console.log("error", error);
        }
    };

    const descargarPDF = () => {
        if (urlPDF) {
            const a = document.createElement('a');
            a.href = urlPDF;
            a.download = `${nroProvisorio && nroProvisorio?.toString() !== '0' ? nroProvisorio : nroDespacho}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    useEffect(() => {
        if (showModalPDFMaria) {
            estadoDespacho === 'Oficializado' ?
                DescargarPDFOficializado()
                : descargarPDFMaria()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModalPDFMaria, estadoDespacho])


    return (
        <>
            <Modal show={showModalPDFMaria} dialogClassName="modal-pdf-maria"
                aria-labelledby="contained-modal-title-vcenter"
                centered backdrop="static"
                size='xl'
                onHide={() => setShowModalPDFMaria(false)} >
                <BlockUi blocking={cargando} message={<b>Descargando PDF..</b>}>
                    <Modal.Header closeButton>
                        <Modal.Title>Descargar Impresión Malvina</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {error ? (
                            <div style={{ textAlign: 'center' }}>
                                <p style={{ fontSize: 20, color: 'red', fontWeight: 'bold' }}>{error}</p>
                                <Button onClick={() => setError('')}>Cerrar</Button>
                            </div>
                        ) : urlPDF ? (
                            <>
                                <object
                                    aria-label='PDF'
                                    data={urlPDF}
                                    type="application/pdf"
                                    width="100%"
                                    height="600px"
                                    style={{ border: "none" }}

                                />
                                <div className="d-flex justify-content-center mt-3">
                                    <Button onClick={descargarPDF}>Descargar PDF</Button>
                                </div>
                            </>
                        ) : (
                            <div className="d-flex justify-content-center align-items-center flex-column">
                                <Form.Group controlId="selectCopies">
                                    <Form.Label>N° Copias</Form.Label>
                                    <Form.Control as="select" disabled>
                                        {[1, 2, 3, 4, 5].map(num => <option key={num}>{num}</option>)}
                                    </Form.Control>
                                </Form.Group>
                                <Button className="mt-3" onClick={descargarPDFMaria}>Generar</Button>
                            </div>
                        )}
                    </Modal.Body>
                </BlockUi>

            </Modal>
        </>
    )
}

export default ModalPDFMaria